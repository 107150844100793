.styleButton {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	font-size: 14px;
	justify-content: center;
	padding: 5px 5px;
	text-align: left;
	vertical-align: middle;
	background-image: none !important;
	font-weight: 700;
	box-shadow: none !important;
	min-height: 25px;
	min-width: 25px;
	color: #45496e;
	background: none;

	&.underline {
		text-decoration: underline;
	}

	&.lineThrough {
		text-decoration: line-through;
	}

	&:hover {
		background-color: #c8cbde !important;
	}
}
