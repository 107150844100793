.message-menu {
	fill: rgb(69, 73, 110);
}

.sub-message-container {
	.gm-fullscreen-control {
		display: none;
	}

	.map-preview {
		a,
		span {
			display: none !important;
		}
	}
}

.DraftEditor-root {
	z-index: 0;
}