@import url('../../media.css');

.contribution-wrapper {
    overflow: hidden;
}

.contribution-wrapper,
.contribution {
    background-image: linear-gradient(
        to top,
        var(--white) 20%,
        var(--desert-storm)
    );
}

.contribution {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    margin: 0 auto;
    padding: 10px 20px;
    max-width: var(--wide-desktop-width);
    width: 100%;
    height: calc(100% - var(--header-height));

    @media (--tall) {
        padding-top: 2rem;
    }

    @media (--lg-up) {
        position: initial;
        overflow: visible;
        justify-content: normal;
        min-height: 84vh;
    }

    @media (--sm-down) {
        padding-bottom: 20px;
    }

    .button {
        min-width: auto;

        @media (--sm-down) {
            border: none;
            padding: 0;
        }

        @media (--md-up) {
            padding: 0 38px;
            height: 57px;
        }
    }

    .contribution-criteria {
        display: block;
        color: var(--black);
        text-transform: uppercase;
        font-size: var(--font-size-sm);
        text-align: center;
        margin-top: 1rem;
        font-weight: 600;

        @media (--md-up) {
            margin-top: 0;
            margin-bottom: 1rem;
        }

        svg {
            fill: var(--black);
            margin-inline-end: 0.5rem;
            vertical-align: text-bottom;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .counter {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;

        b {
            font-style: normal;
        }

        svg {
            margin-inline-end: 10px;
            padding: 7px;
            background: var(--valid-green);
            border-radius: 50%;

            & use,
            & path {
                fill: var(--white);
            }
        }

        .text {
            margin-inline-start: 10px;
            font-size: var(--font-size-xs);
            font-weight: normal;
            font-style: italic;
        }

        &.done {
            background: var(--white);
            border-radius: 29px;
            padding: 7px 20px 7px 7px;
            border: 2px solid var(--grey);
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 47px;
            height: 47px;
            background: var(--white);
            border-radius: 50%;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

            [dir='rtl'] & {
                transform: rotateY(180deg);
            }

            @media (--md-up) {
                width: 57px;
                height: 57px;
            }
        }

        .mobile-break {
            display: block;
            width: 1px;
            height: 1px;
            @media (--md-up) {
                display: none;
            }
        }

        .links {
            font-family: var(--strong-font-family);

            @media (--md-up) {
                margin-inline-end: auto;
                margin-inline-start: 52px;
                font-size: 20px;
            }

            a {
                padding-bottom: 6px;

                @media (--md-up) {
                    padding-bottom: 12px;
                }

                &:first-child {
                    margin-inline-end: 40px;
                }

                &.active {
                    color: var(--blue);
                    border-bottom: 2px solid var(--blue);
                }
            }
        }

        .counter {
            @media (--md-up) {
                display: none;
            }

            &.done {
                display: flex;

                @media (--sm-down) {
                    visibility: hidden;
                }
            }
        }

        .open-share {
            border: 2px solid var(--grey);
            border-radius: 50%;
            margin-inline-start: 20px;
            background: var(--white);

            @media (--sm-down) {
                width: 46px;
                height: 46px;

                & svg {
                    width: 20px;
                }
            }

            @media (--md-up) {
                width: 57px;
                height: 57px;
            }

            &:hover {
                border-color: transparent;
                background: var(--near-black);

                & path {
                    fill: var(--white);
                }
            }
        }
    }

    .instruction {
        margin-top: 23px;
        min-height: 23px;
        color: var(--dark-grey);

        @media (--sm-down) {
            text-align: center;
        }

        @media (--md-up) {
            margin-top: 4vh;
            margin-bottom: 20px;
        }

        svg {
            margin: 0 10px 0 16px;
            width: 23px;
            height: 23px;
            vertical-align: text-top;
        }
    }

    .cards-and-pills {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        .cta-placeholder {
            height: 400px;
        }

        @media (--md-up) {
            flex-direction: row;
        }

        > :first-child {
            display: none;
            flex: 1;
            margin-inline-end: auto;

            @media (--xl-up) {
                display: block;
            }
        }
    }

    .cards-and-instruction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        @media (--xl-up) {
            margin-top: 0;
            max-width: 60%;
        }

        .cards {
            position: relative;
            max-width: 700px;
            height: 200px;
            width: 100%;

            @media (--md-up) {
                max-height: 430px;
                height: 31vh;
            }

            @media (--md-only) {
                margin-right: 20px;
            }
        }

        .card {
            position: absolute;
            left: 0;

            display: flex;
            align-items: center;

            width: 100%;
            height: 100%;

            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
            overflow: auto;
            word-wrap: break-word;

            opacity: 1;
            transition: transform var(--transition-duration-slow) var(--easing),
                opacity var(--transition-duration-slow) var(--easing);

            &.inactive {
                pointer-events: none;
                opacity: 0.5;
                filter: blur(6px);
            }
        }
    }

    .pills {
        @media (--sm-down) {
            margin-top: 20px;
        }

        @media (--md-up) {
            flex: 1;
            display: flex;
        }

        .inner {
            position: relative;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;

            @media (--md-up) {
                margin-top: 0;
                margin-inline-start: auto;
                margin-top: 70px;
                width: max-content;
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .counter {
            display: none;

            @media (--md-up) {
                margin-top: -38px;
                display: flex;
            }
        }
    }

    .primary-buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (--xl-tall) {
            margin-top: 10vh;
        }

        & canvas {
            position: absolute;
            width: 100% !important;
            max-width: calc(var(--wide-desktop-width) - 40px);
            mask-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0) 3%,
                black,
                rgba(0, 0, 0, 0) 97%
            );
        }

        & button {
            z-index: 1;
        }
    }

    .buttons {
        margin-top: 4vh;

        display: flex;
        justify-content: space-between;

        button img {
            margin-inline-end: 10px;
        }

        > * {
            display: flex;

            & > :first-child {
                @media (--md-up) {
                    margin-inline-end: 20px;
                }
            }
        }

        .guidelines-button {
            svg {
                margin-inline-end: 10px;

                path {
                    fill: var(--black);
                }
            }

            @media (--md-down) {
                margin-inline-end: 20px;
            }
        }

        .shortcuts-btn {
            padding: 0;
            width: 60px;
            margin-inline-end: 0;

            img {
                margin-inline-end: 0;
            }
        }

        .skip {
            display: inline-flex;

            & svg {
                margin-inline-end: 13px;

                [dir='rtl'] & {
                    transform: rotateY(180deg);
                }
            }
        }

        .submit {
            margin: 0;

            @media (--sm-down) {
                display: none;
            }

            &.disabled {
                & button {
                    cursor: default;
                    color: var(--warm-grey);
                    background: var(--desert-storm);
                }

                & .background {
                    display: none;
                }
            }

            button {
                border: none;
                border-radius: 50px;
                width: 178px;
                height: 57px;
                color: var(--white);
                background: var(--blue);
                text-transform: uppercase;
                font-family: var(--base-font-family);
                font-weight: 600;
            }

            .background {
                border-radius: 50px;
                margin-top: 10px;
                width: 187px;
                height: 63px;
                background-image: linear-gradient(to left, #88d1f1, #b1b5e5);
                opacity: 0;
            }
        }

        .extra-buttons {
            display: flex;

            & .open-report-button {
                margin-inline-end: 20px;

                @media (--md-down) {
                    padding: 0 20px;
                }
            }
        }
    }

    &.submittable,
    &.first-cta-visible,
    &.second-cta-visible {
        .cards,
        .instruction,
        .primary-buttons,
        .buttons button[type='button'],
        .extra-button,
        .guidelines-button {
            display: none;
            filter: blur(10px);
            pointer-events: none;

            @media (--md-up) {
                display: initial;
            }
        }

        .primary-buttons {
            @media (--xl-up) {
                margin-top: 6vh;
            }
        }

        @media (--sm-down) {
            & .top .counter {
                visibility: hidden;
            }

            & .pills {
                margin-top: 0;

                & .counter {
                    display: flex;
                }

                & .inner {
                    flex-direction: column;
                    align-items: center;

                    & .pill {
                        margin-bottom: 20px;
                    }
                }
            }

            & .buttons {
                justify-content: center;
            }

            & .submit {
                display: flex;
                height: 46px;
            }
        }
    }

    &.first-cta-visible,
    &.second-cta-visible {
        div.buttons {
            filter: blur(10px);
            pointer-events: none;
        }
    }

    .review-instructions {
        font-style: italic;

        @media (--sm-down) {
            margin-bottom: 20px;
            max-width: 220px;
            text-align: center;
            font-size: var(--font-size-xs);
        }

        @media (--md-up) {
            position: absolute;
            right: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: var(--font-size-xl);
            width: 338px;
            text-align: end;
            padding-inline-end: 40px;
        }
    }

    .text-button {
        font-size: var(--font-size-xs);
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: var(--blue);
            text-decoration-color: var(--blue);
        }
    }

    .card-dimensions {
        padding: 0 20px;

        text-align: center;
        font-weight: 600;
        line-height: 1.38;

        background: var(--white);

        @media (--md-up) {
            padding: 0 25px;
            font-size: var(--font-size-xl);
            font-weight: normal;
            letter-spacing: 1.3px;
            line-height: 1.63;
        }

        @media (--lg-up) {
            padding: 0 100px;
        }
    }

    .empty-container {
        margin-bottom: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .error-card {
        margin: 75px auto;
        padding: 30px 25px;
        max-width: 700px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        background: var(--white);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);

        h1 {
            font-weight: 400;
            font-size: 2em;
            margin-bottom: 0.5em;
        }

        p {
            font-size: 1.25em;
        }

        @media (--md-up) {
            padding: 120px 105px;
        }

        .button {
            margin-top: 30px;
            border: none;
            background: var(--red);
            box-shadow: 0 5px 10px color-mod(var(--red) alpha(50%));
            text-transform: uppercase;

            &:hover {
                color: var(--white);
                opacity: 1;
                background: var(--red);
                box-shadow: 0 5px 10px var(--red);

                & path {
                    fill: var(--white);
                }
            }
        }
    }

    .sentence-taxonomy {
        border-top: 1px solid var(--grey);
        background: var(--lighter-grey);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        font-weight: 400;
        font-size: var(--font-size);
        color: var(--warm-grey);

        span {
            display: inline-block;
        }

        @media (--sm-down) {
            font-size: 14px;
            padding: 0.75rem;
        }
    }

    .taxonomy-link,
    .taxonomy-message {
        white-space: nowrap;
    }

    .taxonomy-message {
        @media (--sm-down) {
            white-space: normal;
        }
    }

    .taxonomy-link {
        text-transform: uppercase;
        color: var(--blue);
        font-weight: 600;
        text-decoration: none;
        margin: 0 1rem;

        svg {
            width: 20px;
            height: 20px;
            margin: 0 0.5rem;
            path {
                fill: var(--blue);
            }
        }

        @media (--sm-down) {
            font-size: 90%;
            margin-top: 5px;
        }
    }

    .contribution-speak-form {
        display: flex;
        flex-direction: column;
        align-items: end;

        @media (--md-down) {
            align-items: center;
            margin-bottom: 2rem;
        }

        .labeled-checkbox {
            margin: 0 0 2em;
            width: 60%;
            text-align: center;

            @media (--md-down) {
                width: 80%;
            }

            a {
                color: var(--anchor-text-blue);
            }
        }
    }
}

.shortcuts-modal {
    padding: 80px !important;
    max-width: 770px !important;

    h1 {
        margin-bottom: 50px;
        font-size: var(--font-size-xxl);
        font-weight: normal;
    }

    .shortcuts {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .shortcut {
            margin-inline-end: 50px;
            width: 91px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:last-child {
                margin-inline-end: 0;
            }
        }

        kbd {
            border-radius: 4px;
            border: solid 1px color-mod(var(--black) alpha(5%));
            margin-bottom: 10px;
            width: 57px;
            height: 57px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-family: var(--base-font-family);
        }

        .label {
            font-size: var(--font-size-xs);
            font-style: italic;
            color: var(--warm-grey);
            line-height: 1.5;
        }
    }
}
