.container {
}

.dropdownButton {
    text-align: left;
    position: relative;

    i {
        right: 8px;
        top: 10px;
        position: absolute;
    }
}