.edgebutton {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s;
    border: 2px solid black;
    border-radius: 50%;
    outline: 2px solid white;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
}

.edge-delete:hover {
    box-shadow: 0 0 15px 5px rgba(255, 0, 0, 1);
}

.edge-create-node-draft:hover {
    box-shadow: 0 0 15px 5px rgba(15, 190, 117, 1);
}

.space {
    margin: 15px;
}