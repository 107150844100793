:root {
    --blue: #007bff;
    --indigo: #674eec;
    --purple: #8445f7;
    --pink: #ff4169;
    --red: #c4183c;
    --orange: #fb7906;
    --yellow: #ffb400;
    --green: #17c671;
    --teal: #1adba2;
    --cyan: #00b8d8;
    --white: #fff;
    --gray: #868e96;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #5A6169;
    --success: #17c671;
    --info: #00b8d8;
    --warning: #ffb400;
    --danger: #c4183c;
    --light: #FBFBFB;
    --dark: #212529;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
    font-size: 1rem;
    font-weight: 300;
    color: #5a6169;
    background-color: #fff;
}

a {
    color: #007bff;
    text-decoration: none;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

.lead {
    line-height: 1.875rem;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    margin-bottom: .75rem;
}

.display-1 {
    font-size: 7.451rem;
    line-height: 1;
}

.display-2 {
    font-size: 5.96rem;
    line-height: 1;
}

.display-3 {
    font-size: 4.768rem;
    line-height: 1;
}

.display-4 {
    font-size: 3.815rem;
    line-height: 1;
}

p {
    margin-bottom: 1.75rem;
}

hr {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
    font-size: 80%;
    font-weight: 300;
}

.mark,
mark {
    padding: .2em;
    background-color: #fff09e;
}

.blockquote {
    margin-bottom: .75rem;
    font-size: 1.5rem;
}

.blockquote-footer {
    font-size: 1.125rem;
}

.img-thumbnail {
    padding: 0;
    border: none;
    background-color: #fff;
    border-radius: .375rem;
    box-shadow: none;
}

.figure-img {
    margin-bottom: .75rem;
}

.figure-caption {
    font-size: 1rem;
    color: #868e96;
}

code,
kbd,
pre,
samp {
    font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
    font-size: .75rem;
    padding: .1875rem .8125rem;
}

kbd {
    padding: .1875rem .8125rem;
    font-size: .75rem;
    color: #fff;
    background-color: #212529;
    border-radius: .625rem;
    box-shadow: none;

    kbd {
        font-weight: 500;
    }
}

pre {
    margin-bottom: .75rem;
    font-size: .75rem;
    color: #212529;
    line-height: 1.375rem;
}

.pre-scrollable {
    max-height: 340px;
}

.table {
    background-color: transparent;

    td,
    th {
        padding: .75rem;
    }

    .table {
        background-color: #fff;
    }
}

.table-sm {

    td,
    th {
        padding: .3rem;
    }
}

.table-primary {
    background-color: #b8daff;

    > {

        td,
        th {
            background-color: #b8daff;
        }
    }
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;

    > {

        td,
        th {
            background-color: #9fcdff;
        }
    }
}

.table-secondary {
    background-color: #d1d3d5;

    > {

        td,
        th {
            background-color: #d1d3d5;
        }
    }
}

.table-hover .table-secondary:hover {
    background-color: #c4c6c9;

    > {

        td,
        th {
            background-color: #c4c6c9;
        }
    }
}

.table-success {
    background-color: #beefd7;

    > {

        td,
        th {
            background-color: #beefd7;
        }
    }
}

.table-hover .table-success:hover {
    background-color: #aaeaca;

    > {

        td,
        th {
            background-color: #aaeaca;
        }
    }
}

.table-info {
    background-color: #b8ebf4;

    > {

        td,
        th {
            background-color: #b8ebf4;
        }
    }
}

.table-hover .table-info:hover {
    background-color: #a2e5f1;

    > {

        td,
        th {
            background-color: #a2e5f1;
        }
    }
}

.table-warning {
    background-color: #ffeab8;

    > {

        td,
        th {
            background-color: #ffeab8;
        }
    }
}

.table-hover .table-warning:hover {
    background-color: #ffe29f;

    > {

        td,
        th {
            background-color: #ffe29f;
        }
    }
}

.table-danger {
    background-color: #eebec8;

    > {

        td,
        th {
            background-color: #eebec8;
        }
    }
}

.table-hover .table-danger:hover {
    background-color: #e9aab7;

    > {

        td,
        th {
            background-color: #e9aab7;
        }
    }
}

.table-light {
    background-color: #fefefe;

    > {

        td,
        th {
            background-color: #fefefe;
        }
    }
}

.table-hover .table-light:hover {
    background-color: #f1f1f1;

    > {

        td,
        th {
            background-color: #f1f1f1;
        }
    }
}

.table-dark {
    background-color: #c1c2c3;

    > {

        td,
        th {
            background-color: #c1c2c3;
        }
    }
}

.table-hover .table-dark:hover {
    background-color: #b4b5b6;

    > {

        td,
        th {
            background-color: #b4b5b6;
        }
    }
}

.table-active {
    background-color: rgba(0, 0, 0, 0.075);

    > {

        td,
        th {
            background-color: rgba(0, 0, 0, 0.075);
        }
    }
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);

    > {

        td,
        th {
            background-color: rgba(0, 0, 0, 0.075);
        }
    }
}

.table {
    .thead-dark th {
        color: #fff;
        background-color: #212529;
        border-color: #32383e;
    }

    .thead-light th {
        color: #495057;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
}

.table-dark {
    color: #fff;
    background-color: #212529;

    td,
    th,
    thead th {
        border-color: #32383e;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05);
    }

    &.table-hover tbody tr:hover {
        background-color: rgba(255, 255, 255, 0.075);
    }
}

.form-control {
    height: auto;
    padding: .4375rem .75rem;
    font-size: .8125rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e1e5eb;
    font-weight: 300;
    will-change: border-color, box-shadow;
    border-radius: .25rem;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:hover {
        border-color: #b3bdcc;
    }

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #007bff;
        box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);

        &:hover {
            border-color: #007bff;
        }
    }

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
        color: #868e96;
    }

    &:disabled,
    &[readonly] {
        background-color: #f5f6f7;
    }

    &:disabled:hover {
        border-color: #e1e5eb;
        cursor: not-allowed;
    }

    &[readonly] {
        &:hover {
            border-color: #e1e5eb;
            cursor: not-allowed;
        }

        &:not(:disabled):focus {
            box-shadow: none;
            border-color: #e1e5eb;
        }
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: calc(2.09375rem + 2px);
    }

    &:focus::-ms-value {
        color: #495057;
        background-color: #fff;
    }

    &:hover {
        cursor: pointer;
    }
}

form label:hover {
    cursor: pointer;
}

.col-form-label {
    padding-top: calc(.4375rem + 1px);
    padding-bottom: calc(.4375rem + 1px);
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(.8125rem + 1px);
    padding-bottom: calc(.8125rem + 1px);
    font-size: .875rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(.4286rem + 1px);
    padding-bottom: calc(.4286rem + 1px);
    font-size: .6875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
    line-height: 1.5;
    font-weight: 300;
}

.form-control-sm {
    padding: .4286rem .625rem;
    font-size: .6875rem;
    line-height: 1.5;
    border-radius: .25rem;
}

.input-group-sm> {
    .form-control {
        padding: .4286rem .625rem;
        font-size: .6875rem;
        line-height: 1.5;
        border-radius: .25rem;
    }

    .input-group-append> {

        .btn,
        .input-group-text {
            padding: .4286rem .625rem;
            font-size: .6875rem;
            line-height: 1.5;
            border-radius: .25rem;
        }
    }

    .input-group-middle>.input-group-text {
        padding: .4286rem .625rem;
        font-size: .6875rem;
        line-height: 1.5;
        border-radius: .25rem;
    }

    .input-group-prepend> {

        .btn,
        .input-group-text {
            padding: .4286rem .625rem;
            font-size: .6875rem;
            line-height: 1.5;
            border-radius: .25rem;
        }
    }

    .input-group-append>select {

        &.btn:not([size]):not([multiple]),
        &.input-group-text:not([size]):not([multiple]) {
            height: calc(1.88845rem + 2px);
        }
    }

    .input-group-middle>select.input-group-text:not([size]):not([multiple]) {
        height: calc(1.88845rem + 2px);
    }

    .input-group-prepend>select {

        &.btn:not([size]):not([multiple]),
        &.input-group-text:not([size]):not([multiple]) {
            height: calc(1.88845rem + 2px);
        }
    }

    select.form-control:not([size]):not([multiple]) {
        height: calc(1.88845rem + 2px);
    }
}

select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.88845rem + 2px);
}

.form-control-lg {
    padding: .8125rem .8125rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .375rem;
}

.input-group-lg> {
    .form-control {
        padding: .8125rem .8125rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .375rem;
    }

    .input-group-append> {

        .btn,
        .input-group-text {
            padding: .8125rem .8125rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .375rem;
        }
    }

    .input-group-middle>.input-group-text {
        padding: .8125rem .8125rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .375rem;
    }

    .input-group-prepend> {

        .btn,
        .input-group-text {
            padding: .8125rem .8125rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .375rem;
        }
    }

    .input-group-append>select {

        &.btn:not([size]):not([multiple]),
        &.input-group-text:not([size]):not([multiple]) {
            height: calc(2.9375rem + 2px);
        }
    }

    .input-group-middle>select.input-group-text:not([size]):not([multiple]) {
        height: calc(2.9375rem + 2px);
    }

    .input-group-prepend>select {

        &.btn:not([size]):not([multiple]),
        &.input-group-text:not([size]):not([multiple]) {
            height: calc(2.9375rem + 2px);
        }
    }

    select.form-control:not([size]):not([multiple]) {
        height: calc(2.9375rem + 2px);
    }
}

select.form-control-lg:not([size]):not([multiple]) {
    height: calc(2.9375rem + 2px);
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    margin-top: .25rem;
}

.form-check {
    padding-left: 1.25rem;
}

.form-check-input {
    margin-top: .313rem;
    margin-left: -1.25rem;

    &:disabled~.form-check-label {
        color: #868e96;
    }
}

.form-check-inline {
    margin-right: .75rem;

    .form-check-input {
        margin-right: .3125rem;
    }
}

.valid-feedback {
    margin-top: .25rem;
    font-size: 80%;
    color: #17c671;
}

.valid-tooltip {
    background-color: rgba(23, 198, 113, 0.8);
}

.custom-select.is-valid,
.form-control.is-valid {
    border-color: #17c671;
    box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1);
}

.was-validated {

    .custom-select:valid,
    .form-control:valid {
        border-color: #17c671;
        box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1);
    }
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus {
    box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1), 0 1px 1px 0.1rem rgba(23, 198, 113, 0.2);
}

.was-validated {

    .custom-select:valid:focus,
    .form-control:valid:focus {
        box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1), 0 1px 1px 0.1rem rgba(23, 198, 113, 0.2);
    }
}

.custom-select.is-valid:hover,
.form-control.is-valid:hover {
    border-color: #17c671;
}

.was-validated {

    .custom-select:valid:hover,
    .form-control:valid:hover {
        border-color: #17c671;
    }
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label,
.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #17c671;
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    background-color: #57eca4;
    border-color: #2ae68b;
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    background-color: #2ae68b;
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    color: #17c671;
    border-color: #17c671;
}

.custom-file-input.is-valid~.custom-file-label::after,
.was-validated .custom-file-input:valid~.custom-file-label::after {
    background-color: #b3f6d5;
    border-color: #2ae68b;
    color: #17c671;
}

.custom-file-input:focus.is-valid~.custom-file-label,
.was-validated .custom-file-input:focus:valid~.custom-file-label {
    border-color: #17c671;
    box-shadow: 0 5px 11.5px rgba(23, 198, 113, 0.1), 0 1px 1px 0.1rem rgba(23, 198, 113, 0.2);
}

.custom-file-input:hover.is-valid~.custom-file-label,
.was-validated .custom-file-input:hover:valid~.custom-file-label {
    border-color: #17c671;
}

.custom-toggle .custom-control-input:not(:checked).is-valid~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:not(:checked):valid~.custom-control-label::before {
    background-color: #fff;
}

.custom-toggle .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:valid~.custom-control-label::before {
    background-color: #17c671;
}

.custom-toggle .custom-control-input.is-invalid~.custom-control-label::after,
.was-validated .custom-toggle .custom-control-input:invalid~.custom-control-label::after {
    background-color: #eb8c95;
}

.custom-toggle .custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.invalid-feedback {
    margin-top: .25rem;
    font-size: 80%;
    color: #c4183c;
    text-align: left;
}

.invalid-tooltip {
    background-color: rgba(196, 24, 60, 0.8);
}

.custom-select.is-invalid,
.form-control.is-invalid {
    border-color: #c4183c;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1);
}

.was-validated {

    .custom-select:invalid,
    .form-control:invalid {
        border-color: #c4183c;
        box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1);
    }
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus {
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1), 0 1px 1px 0.1rem rgba(196, 24, 60, 0.2);
}

.was-validated {

    .custom-select:invalid:focus,
    .form-control:invalid:focus {
        box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1), 0 1px 1px 0.1rem rgba(196, 24, 60, 0.2);
    }
}

.custom-select.is-invalid:hover,
.form-control.is-invalid:hover {
    border-color: #c4183c;
}

.was-validated {

    .custom-select:invalid:hover,
    .form-control:invalid:hover {
        border-color: #c4183c;
    }
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label,
.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #c4183c;
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    background-color: #ea5876;
    border-color: #e52a51;
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    background-color: #e52a51;
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(196, 24, 60, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    color: #c4183c;
    border-color: #c4183c;
}

.custom-file-input.is-invalid~.custom-file-label::after,
.was-validated .custom-file-input:invalid~.custom-file-label::after {
    background-color: #f6b2c0;
    border-color: #e52a51;
    color: #c4183c;
}

.custom-file-input:focus.is-invalid~.custom-file-label,
.was-validated .custom-file-input:focus:invalid~.custom-file-label {
    border-color: #c4183c;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, 0.1), 0 1px 1px 0.1rem rgba(196, 24, 60, 0.2);
}

.custom-file-input:hover.is-invalid~.custom-file-label,
.was-validated .custom-file-input:hover:invalid~.custom-file-label {
    border-color: #c4183c;
}

.custom-toggle .custom-control-input:not(:checked).is-invalid~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:not(:checked):invalid~.custom-control-label::before {
    background-color: #fff;
}

.custom-toggle .custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:valid~.custom-control-label::before {
    background-color: #17c671;
}

.custom-toggle .custom-control-input.is-invalid~.custom-control-label::after,
.was-validated .custom-toggle .custom-control-input:invalid~.custom-control-label::after {
    background-color: #eb8c95;
}

.custom-toggle .custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0.313rem 0.719rem rgba(196, 24, 60, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}

.btn {
    font-weight: 700;
    font-family: 'Poppins', 'Bai Jamjuree', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border: 1px solid transparent;
    padding: .5625rem 1rem;
    font-size: 14px;
    line-height: 1.125;
    border-radius: .25rem;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &.hover,
    &:hover {
        cursor: pointer;
    }

    &.focus,
    &:focus {
        box-shadow: none;
    }

    &:not([disabled]):not(.disabled) {

        &.active,
        &:active {
            background-image: none;
            box-shadow: none;
        }
    }

    &.btn-squared {
        border-radius: 0;
    }

    &.btn-pill {
        border-radius: 50px;
    }
}

.btn-primary {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #006fe6;
        border-color: #006fe6;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #007bff;
        border-color: #007bff;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #006fe6;
            border-color: #0062cc;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006fe6;
    border-color: #0062cc;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-secondary {
    color: #fff;
    border-color: #5a6169;
    background-color: #5a6169;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #4e545b;
        border-color: #4e545b;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(90, 97, 105, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15), 0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #5a6169;
        border-color: #5a6169;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #4e545b;
            border-color: #42484e;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #4e545b;
    border-color: #42484e;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-success {
    color: #fff;
    border-color: #17c671;
    background-color: #17c671;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #14af64;
        border-color: #14af64;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(23, 198, 113, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(23, 198, 113, 0.15), 0 3px 15px rgba(23, 198, 113, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #17c671;
        border-color: #17c671;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #14af64;
            border-color: #129857;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #14af64;
    border-color: #129857;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-info {
    color: #fff;
    border-color: #00b8d8;
    background-color: #00b8d8;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #00a2bf;
        border-color: #00a2bf;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 184, 216, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 184, 216, 0.15), 0 3px 15px rgba(0, 184, 216, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #00b8d8;
        border-color: #00b8d8;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #00a2bf;
            border-color: #008da5;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a2bf;
    border-color: #008da5;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-warning {
    color: #212529;
    border-color: #ffb400;
    background-color: #ffb400;
    box-shadow: none;

    &:hover {
        color: #212529;
        background-color: #e6a200;
        border-color: #e6a200;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 180, 0, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(255, 180, 0, 0.15), 0 3px 15px rgba(255, 180, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #ffb400;
        border-color: #ffb400;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #e6a200;
            border-color: #cc9000;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e6a200;
    border-color: #cc9000;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-danger {
    color: #fff;
    border-color: #c4183c;
    background-color: #c4183c;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #ad1535;
        border-color: #ad1535;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(196, 24, 60, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(196, 24, 60, 0.15), 0 3px 15px rgba(196, 24, 60, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #c4183c;
        border-color: #c4183c;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #ad1535;
            border-color: #97122e;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ad1535;
    border-color: #97122e;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-light {
    color: #212529;
    border-color: #fbfbfb;
    background-color: #fbfbfb;
    box-shadow: none;

    &:hover {
        color: #212529;
        background-color: #eee;
        border-color: #eee;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(251, 251, 251, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(251, 251, 251, 0.15), 0 3px 15px rgba(251, 251, 251, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #fbfbfb;
        border-color: #fbfbfb;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #eee;
            border-color: #e2e2e2;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #eee;
    border-color: #e2e2e2;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-dark {
    color: #fff;
    border-color: #212529;
    background-color: #212529;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #16181b;
        border-color: #16181b;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(33, 37, 41, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.15), 0 3px 15px rgba(33, 37, 41, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #212529;
        border-color: #212529;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #16181b;
            border-color: #0a0c0d;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #16181b;
    border-color: #0a0c0d;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-white {
    color: #212529;
    border-color: #fff;
    background-color: #fff;
    box-shadow: none;

    &:hover {
        color: #212529;
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 255, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15), 0 3px 15px rgba(255, 255, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #f2f2f2;
            border-color: #e6e6e6;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-white.dropdown-toggle {
    color: #212529;
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-black {
    color: #fff;
    border-color: #000;
    background-color: #000;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #000;
        border-color: #000;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #000;
            border-color: #000;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-primary {
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
    color: #007bff;

    &:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #007bff;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
    }
}

.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-secondary {
    background-color: transparent;
    background-image: none;
    border-color: #5a6169;
    color: #5a6169;

    &:hover {
        color: #fff;
        background-color: #5a6169;
        border-color: #5a6169;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(90, 97, 105, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15), 0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #5a6169;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #5a6169;
            border-color: #5a6169;
        }
    }
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5a6169;
    border-color: #5a6169;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-success {
    background-color: transparent;
    background-image: none;
    border-color: #17c671;
    color: #17c671;

    &:hover {
        color: #fff;
        background-color: #17c671;
        border-color: #17c671;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(23, 198, 113, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(23, 198, 113, 0.15), 0 3px 15px rgba(23, 198, 113, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #17c671;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #17c671;
            border-color: #17c671;
        }
    }
}

.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #17c671;
    border-color: #17c671;
}

.btn-outline-success:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-info {
    background-color: transparent;
    background-image: none;
    border-color: #00b8d8;
    color: #00b8d8;

    &:hover {
        color: #fff;
        background-color: #00b8d8;
        border-color: #00b8d8;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 184, 216, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 184, 216, 0.15), 0 3px 15px rgba(0, 184, 216, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #00b8d8;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #00b8d8;
            border-color: #00b8d8;
        }
    }
}

.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00b8d8;
    border-color: #00b8d8;
}

.btn-outline-info:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-warning {
    background-color: transparent;
    background-image: none;
    border-color: #ffb400;
    color: #ffb400;

    &:hover {
        color: #212529;
        background-color: #ffb400;
        border-color: #ffb400;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 180, 0, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(255, 180, 0, 0.15), 0 3px 15px rgba(255, 180, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #ffb400;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #ffb400;
            border-color: #ffb400;
        }
    }
}

.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffb400;
    border-color: #ffb400;
}

.btn-outline-warning:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-danger {
    background-color: transparent;
    background-image: none;
    border-color: #c4183c;
    color: #c4183c;

    &:hover {
        color: #fff;
        background-color: #c4183c;
        border-color: #c4183c;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(196, 24, 60, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(196, 24, 60, 0.15), 0 3px 15px rgba(196, 24, 60, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #c4183c;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #c4183c;
            border-color: #c4183c;
        }
    }
}

.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #c4183c;
    border-color: #c4183c;
}

.btn-outline-danger:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-light {
    background-color: transparent;
    background-image: none;
    border-color: #fbfbfb;
    color: #212529;

    &:hover {
        color: #212529;
        background-color: #fbfbfb;
        border-color: #fbfbfb;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(251, 251, 251, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(251, 251, 251, 0.15), 0 3px 15px rgba(251, 251, 251, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #fbfbfb;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #fbfbfb;
            border-color: #fbfbfb;
        }
    }
}

.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fbfbfb;
    border-color: #fbfbfb;
}

.btn-outline-light:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-dark {
    background-color: transparent;
    background-image: none;
    border-color: #212529;
    color: #212529;

    &:hover {
        color: #fff;
        background-color: #212529;
        border-color: #212529;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(33, 37, 41, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(33, 37, 41, 0.15), 0 3px 15px rgba(33, 37, 41, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #212529;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #212529;
            border-color: #212529;
        }
    }
}

.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}

.btn-outline-dark:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-white {
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    color: #212529;
    color: #fff;

    &:hover {
        color: #212529;
        background-color: #fff;
        border-color: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 255, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15), 0 3px 15px rgba(255, 255, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #fff;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #212529;
            background-color: #fff;
            border-color: #fff;
        }
    }
}

.show>.btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-white:not(:disabled):not(.disabled) {

    &.active,
    &:active {
        color: #000;
    }
}

.btn-outline-black {
    background-color: transparent;
    background-image: none;
    border-color: #000;
    color: #000;
    color: #000;

    &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 0, 0, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #000;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #000;
            border-color: #000;
        }
    }
}

.show>.btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-black.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-black:not(:disabled):not(.disabled) {

    &.active,
    &:active {
        color: #fff;
    }
}

.btn-link {
    font-weight: 300;
    color: #007bff;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }

    &.focus,
    &:focus {
        text-decoration: underline;
    }

    &:disabled {
        color: #868e96;
    }
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .75rem 1.75rem;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: .5rem;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .4286rem .875rem;
    font-size: .6875rem;
    line-height: 1.5;
    border-radius: .25rem;
}

.btn-block+.btn-block {
    margin-top: .5rem;
}

.fade {
    transition: opacity .2s ease-in-out;
}

.collapsing {
    transition: height 350ms ease-in-out;
}

i.material-icons {
    font-size: inherit;
    position: relative;
    top: 2px;
}

.dropdown-menu {
    z-index: 1000;
    min-width: 10rem;
    margin: 0 0 0;
    font-size: 1rem;
    color: #5a6169;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: .375rem;
    box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
}

.dropdown-menu-small {
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.11), 0 3px 10px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
    padding: .25rem 0;
    font-size: .813rem;

    .dropdown-item {
        padding: .375rem .875rem;
        font-size: .813rem;
    }

    .dropdown-divider {
        margin: .25rem 0;
    }
}

.dropup .dropdown-menu {
    margin-bottom: 0;
}

.dropright .dropdown-menu {
    margin-left: 0;
}

.dropleft .dropdown-menu {
    margin-right: 0;
}

.dropdown-divider {
    height: 0;
    margin: .75rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    padding: .5rem 1.25rem;
    font-weight: 300;
    color: #212529;
    font-size: .9375rem;
    transition: background-color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:focus,
    &:hover {
        color: #16181b;
        background-color: #eceeef;
    }

    &.active,
    &:active {
        color: #fff;
        background-color: #c3c7cc;
    }

    &.disabled,
    &:disabled {
        color: #868e96;
    }

    &.disabled:hover,
    &:disabled:hover {
        background: 0 0;
        cursor: not-allowed;
    }
}

.dropdown-header {
    padding: 10px 1.25rem;
    font-size: .6875rem;
    color: #868e96;
}

.btn-group {
    .btn+ {

        .btn,
        .btn-group {
            margin-left: -1px;
        }
    }

    .btn-group+ {

        .btn,
        .btn-group {
            margin-left: -1px;
        }
    }
}

.btn-group-vertical {
    .btn+ {

        .btn,
        .btn-group {
            margin-left: -1px;
        }
    }

    .btn-group+ {

        .btn,
        .btn-group {
            margin-left: -1px;
        }
    }
}

.btn-group> {

    .btn-group:not(:last-child)>.btn,
    .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-group:not(:first-child)>.btn,
    .btn:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .65625rem;
    padding-left: .65625rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

    &.btn-link {
        box-shadow: none;
    }
}

.btn-group-vertical> {
    .btn+ {

        .btn,
        .btn-group {
            margin-top: -1px;
        }
    }

    .btn-group {
        + {

            .btn,
            .btn-group {
                margin-top: -1px;
            }
        }

        &:not(:last-child)>.btn {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .btn:not(:last-child):not(.dropdown-toggle) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group:not(:first-child)>.btn,
    .btn:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.input-group {
    > {
        .custom-file+ {

            .custom-file,
            .custom-select,
            .form-control {
                margin-left: -1px;
            }
        }

        .custom-select+ {

            .custom-file,
            .custom-select,
            .form-control {
                margin-left: -1px;
            }
        }

        .form-control+ {

            .custom-file,
            .custom-select,
            .form-control {
                margin-left: -1px;
            }
        }

        .custom-select:not(:last-child),
        .form-control:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .custom-select:not(:first-child),
        .form-control:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .custom-file {
            &:not(:last-child) .custom-file-label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:not(:first-child) .custom-file-label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    &.input-group-seamless> {
        .form-control {
            border-radius: .25rem;
        }

        .input-group-append,
        .input-group-prepend {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 4;
        }

        .input-group-append .input-group-text,
        .input-group-prepend .input-group-text {
            padding: .75rem .625rem;
            background: 0 0;
            border: none;
        }

        .input-group-append {
            right: 0;
        }

        .input-group-middle {
            right: 0;
            left: 0;
        }

        .input-group-prepend {
            left: 0;
        }

        .custom-select:not(:last-child),
        .form-control:not(:last-child) {
            padding-right: 40px;
        }

        .custom-select:not(:first-child),
        .form-control:not(:first-child) {
            padding-left: 40px;
        }
    }
}

.input-group-append {
    .btn+ {

        .btn,
        .input-group-text {
            margin-left: -1px;
        }
    }

    .input-group-text+ {

        .btn,
        .input-group-text {
            margin-left: -1px;
        }
    }
}

.input-group-prepend {
    .btn+ {

        .btn,
        .input-group-text {
            margin-left: -1px;
        }
    }

    .input-group-text+ {

        .btn,
        .input-group-text {
            margin-left: -1px;
        }
    }

    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: #abb6bf;
    background-color: #fff;
    border: 1px solid #e1e5eb;
    border-radius: .25rem;
}

.input-group> {
    .input-group-append {
        &:last-child> {

            .btn:not(:last-child):not(.dropdown-toggle),
            .input-group-text:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:not(:last-child)> {

            .btn,
            .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .input-group-prepend> {

        .btn,
        .input-group-text {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .input-group-append> {

        .btn,
        .input-group-text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .input-group-prepend {
        &:first-child> {

            .btn:not(:first-child),
            .input-group-text:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:not(:first-child)> {

            .btn,
            .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .input-group-middle> {

        .btn,
        .input-group-text {
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }
    }
}

.input-group-middle {
    display: -ms-flexbox;
    display: flex;
}

.custom-control {
    min-height: 1.5rem;
    padding-left: 1.688rem;

    &:hover {
        cursor: pointer;
    }

    .custom-control-label:before {
        pointer-events: all;
    }
}

.custom-control-inline {
    margin-right: 1rem;
}

.custom-control-input {
    &:checked~.custom-control-label::before {
        color: #fff;
        border-color: transparent;
        background-color: #007bff;
        box-shadow: none;
    }

    &:focus~.custom-control-label::before {
        box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
    }

    &:active~.custom-control-label::before {
        color: #fff;
        background-color: #b3d7ff;
        box-shadow: none;
    }

    &:disabled~.custom-control-label {
        color: #868e96;

        &:hover {
            cursor: not-allowed;
        }

        &::before {
            background-color: #e9ecef;
        }
    }
}

.custom-control-label {
    position: static;

    &:hover {
        cursor: pointer;
    }

    &::before {
        top: .1875rem;
        left: 0;
        width: 1.125rem;
        height: 1.125rem;
        background-color: #fff;
        border: 1px solid #e1e5eb;
        transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
        box-shadow: none;
    }

    &::after {
        top: .1875rem;
        width: 1.125rem;
        height: 1.125rem;
        background-size: 50% 50%;
    }
}

.custom-checkbox {
    .custom-control-label {
        &::before {
            border-radius: 2px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 7px;
            width: 5px;
            height: 11px;
            opacity: 0;
            -webkit-transform: rotate(45deg) scale(0);
            transform: rotate(45deg) scale(0);
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
            transition: transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
            transition: transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), -webkit-transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
            transition-delay: .1s;
        }
    }

    .custom-control-input {
        &:checked~.custom-control-label {
            &::before {
                background-image: none;
            }

            &::after {
                opacity: 1;
                -webkit-transform: rotate(45deg) scale(1);
                transform: rotate(45deg) scale(1);
                background-image: none;
            }
        }

        &:indeterminate~.custom-control-label {
            &::before {
                border: none;
                background-color: #007bff;
                box-shadow: none;
            }

            &::after {
                content: '';
                position: absolute;
                -webkit-transform: scale(1);
                transform: scale(1);
                background-image: none;
                background-color: #fff;
                border: none;
                width: 10px;
                height: 2px;
                top: 11px;
                left: 4px;
                opacity: 1;
                transition: none;
            }
        }

        &:disabled:checked~.custom-control-label {
            &::before {
                background: #e9ecef;
                border-color: #e1e5eb;
            }

            &::after {
                border-color: #e1e5eb;
            }
        }
    }
}

.custom-radio {
    .custom-control-label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            content: '';
            border-radius: 50%;
            -webkit-transform: scale(0);
            transform: scale(0);
            background-image: none !important;
            position: absolute;
            background: #fff;
            width: 8px;
            height: 8px;
            top: 8px;
            left: 5px;
            transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
            transition-delay: .1s;
            opacity: 0;
            transform: scale(0);
        }
    }

    .custom-control-input {
        &:checked~.custom-control-label {
            &::before {
                background-color: #007bff;
            }

            &::after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }

        &:disabled:checked~.custom-control-label {
            &::before {
                background-color: #a8aeb4;
                background: #e9ecef;
                border-color: #e1e5eb;
            }

            &::after {
                background: #e1e5eb;
            }
        }
    }
}

.custom-select {
    height: calc(2.09375rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #e1e5eb;
    font-weight: 300;
    font-size: .8125rem;
    transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: .25rem;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);

        &::-ms-value {
            color: #495057;
            background-color: #fff;
        }
    }

    &:hover:not(:focus):not(:disabled) {
        cursor: pointer;
        border-color: #b3bdcc;
    }

    &[multiple],
    &[size]:not([size="1"]) {
        padding-right: .75rem;
    }

    &:disabled {
        color: #868e96;
        background-color: #e9ecef;
    }
}

.custom-select-sm {
    height: calc(1.88845rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .75rem;
}

.custom-select-lg {
    height: calc(2.9375rem + 2px);
    font-size: 1.25rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.custom-file {
    height: calc(2.0625rem + 2px);
    font-size: .8125rem;
    transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.custom-file-input {
    min-width: 14rem;
    height: calc(2.0625rem + 2px);

    &:focus~.custom-file-label {
        border-color: #007bff;
        color: #495057;
        box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);

        &::after {
            border-color: #007bff;
            color: #007bff;
            background: #e6f2ff;
        }

        &:hover {
            border-color: #007bff;
        }
    }

    &:lang(en)~.custom-file-label::after {
        content: "Browse";
    }

    &:not(:disabled):hover {
        cursor: pointer;

        ~.custom-file-label {
            border-color: #b3bdcc;

            &:before {
                border-color: #b3bdcc;
            }
        }
    }

    &:disabled+.custom-file-label {
        color: #868e96;
        background-color: #f8f9fa;
    }
}

.custom-file-label {
    height: calc(2.0625rem + 2px);
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e1e5eb;
    font-weight: 300;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border-color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: .25rem;

    &::after {
        padding: .5rem 1rem;
        height: calc(calc(2.0625rem + 2px) - 1px * 2);
        line-height: 1.5;
        color: #495057;
        border-left: 1px solid #e1e5eb;
        background-color: #e9ecef;
        border-radius: 0 .25rem .25rem 0;
    }
}

.custom-toggle {
    position: relative;
    padding-left: 3.75rem;

    .custom-control-label {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 3.125rem;
            height: 1.75rem;
            background: #fff;
            border-radius: 100px;
            border: .0625rem solid #e1e5eb;
        }

        &::after {
            content: '';
            position: absolute;
            top: .25rem;
            left: .25rem;
            width: 1.25rem;
            height: 1.25rem;
            background: #e1e5eb;
            border-radius: 6.25rem;
            transition: 350ms;
        }
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    background: #17c671;
                    border-color: #17c671;
                }

                &::after {
                    left: 2.875rem;
                    -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
                    background: #fff;
                }
            }

            &:disabled~.custom-control-label {
                &::before {
                    background: #e9ecef;
                    border-color: #e1e5eb;
                }

                &::after {
                    background: #e1e5eb;
                }
            }
        }

        &:active {
            &:not(:disabled)~.custom-control-label::after {
                width: 1.625rem;
            }

            &:not(:checked)~.custom-control-label::before {
                background-color: #fff;
            }
        }

        &:disabled:active~.custom-control-label::before {
            background-color: #e9ecef;
        }

        &:focus {
            ~.custom-control-label::before {
                box-shadow: 0 0.313rem 0.719rem rgba(23, 198, 113, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
            }

            &:not(:checked)~.custom-control-label::before {
                box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
            }
        }
    }

    &.custom-toggle-sm {
        padding-left: 2.625rem;

        .custom-control-label {
            &::before {
                top: .1875rem;
                position: absolute;
                display: block;
                width: 2.1875rem;
                height: 1.125rem;
                background: #fff;
                border-radius: 100px;
                border: .0625rem solid #e1e5eb;
            }

            &::after {
                content: '';
                position: absolute;
                top: .375rem;
                left: .1875rem;
                width: .75rem;
                height: .75rem;
            }
        }

        .custom-control-input {
            &:checked~.custom-control-label::after {
                left: 1.9375rem;
            }

            &:active:not(:disabled)~.custom-control-label::after {
                width: 1rem;
            }
        }
    }
}

.nav {
    font-size: .875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.nav-link {
    padding: .625rem .625rem;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &.disabled {
        color: #868e96;
    }
}

.nav-tabs {
    border-bottom: 1px solid #d1d4d8;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    padding-bottom: 16px;

    .nav-item {
        margin-bottom: -1px;
    }

    .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: .375rem;
        border-top-right-radius: .375rem;

        &:focus,
        &:hover {
            border-color: #e9ecef;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        &:hover {
            border-color: #e7e9ea;
        }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
        color: #495057;
        background-color: #fff;
        border-color: #ddd;
    }

    .dropdown-menu {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.nav-pills {
    .nav-link {
        border-radius: .375rem;

        &.active {
            color: #fff;
            background-color: #007bff;
        }
    }

    .show>.nav-link {
        color: #fff;
        background-color: #007bff;
    }

    &:hover {
        background-color: #fdfdfd;
    }
}

.nav-outlined-pills .nav-link {
    border-radius: .375rem;
    border: 1px solid transparent;

    &.active {
        background: 0 0;
        color: #007bff;
        border-color: #007bff;
    }
}

.show>.nav-outlined-pills .nav-link {
    background: 0 0;
    color: #007bff;
    border-color: #007bff;
}

.nav-outlined-pills .nav-link:hover {
    border-color: #e7e9ea;
}

.nav-blue {
    .nav-link {
        &.active {
            background-color: #007bff;
            border-color: #0074f0;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #007bff;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #3395ff;
            color: #007bff;

            &:hover {
                border-color: #3395ff;
            }
        }

        color: #007bff;
    }
}

.nav-indigo {
    .nav-link {
        &.active {
            background-color: #674eec;
            border-color: #5b40eb;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #674eec;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #8f7cf1;
            color: #674eec;

            &:hover {
                border-color: #8f7cf1;
            }
        }

        color: #674eec;
    }
}

.nav-purple {
    .nav-link {
        &.active {
            background-color: #8445f7;
            border-color: #7a36f6;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #8445f7;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #a476f9;
            color: #8445f7;

            &:hover {
                border-color: #a476f9;
            }
        }

        color: #8445f7;
    }
}

.nav-pink {
    .nav-link {
        &.active {
            background-color: #ff4169;
            border-color: #ff325d;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #ff4169;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #ff7491;
            color: #ff4169;

            &:hover {
                border-color: #ff7491;
            }
        }

        color: #ff4169;
    }
}

.nav-red {
    .nav-link {
        &.active {
            background-color: #c4183c;
            border-color: #b61638;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #c4183c;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #e52a51;
            color: #c4183c;

            &:hover {
                border-color: #e52a51;
            }
        }

        color: #c4183c;
    }
}

.nav-orange {
    .nav-link {
        &.active {
            background-color: #fb7906;
            border-color: #ee7204;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #fb7906;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #fc9438;
            color: #fb7906;

            &:hover {
                border-color: #fc9438;
            }
        }

        color: #fb7906;
    }
}

.nav-yellow {
    .nav-link {
        &.active {
            background-color: #ffb400;
            border-color: #f0a900;
            color: #212529;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #ffb400;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #ffc333;
            color: #ffb400;

            &:hover {
                border-color: #ffc333;
            }
        }

        color: #ffb400;
    }
}

.nav-green {
    .nav-link {
        &.active {
            background-color: #17c671;
            border-color: #15b869;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #17c671;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #2ae68b;
            color: #17c671;

            &:hover {
                border-color: #2ae68b;
            }
        }

        color: #17c671;
    }
}

.nav-teal {
    .nav-link {
        &.active {
            background-color: #1adba2;
            border-color: #18cd98;
            color: #212529;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #1adba2;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #40e8b7;
            color: #1adba2;

            &:hover {
                border-color: #40e8b7;
            }
        }

        color: #1adba2;
    }
}

.nav-cyan {
    .nav-link {
        &.active {
            background-color: #00b8d8;
            border-color: #00abc9;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #00b8d8;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #0cdbff;
            color: #00b8d8;

            &:hover {
                border-color: #0cdbff;
            }
        }

        color: #00b8d8;
    }
}

.nav-white {
    .nav-link {
        &.active {
            background-color: #fff;
            border-color: #f7f7f7;
            color: #212529;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #fff;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #fff;
            color: #fff;

            &:hover {
                border-color: #fff;
            }
        }

        color: #fff;
    }
}

.nav-gray {
    .nav-link {
        &.active {
            background-color: #868e96;
            border-color: #7e868f;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #868e96;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #a1a8ae;
            color: #868e96;

            &:hover {
                border-color: #a1a8ae;
            }
        }

        color: #868e96;
    }
}

.nav-gray-dark {
    .nav-link {
        &.active {
            background-color: #343a40;
            border-color: #2d3238;
            color: #fff;
        }

        &.disabled {
            color: #868e96;

            &:hover {
                cursor: not-allowed;
                border-color: transparent;
            }
        }

        color: #343a40;
    }

    &.nav-outlined-pills .nav-link {
        &.active {
            background: 0 0;
            border-color: #4b545c;
            color: #343a40;

            &:hover {
                border-color: #4b545c;
            }
        }

        color: #343a40;
    }
}

.navbar {
    padding: .75rem 1.5rem;
}

.navbar-brand {
    padding-top: .7rem;
    padding-bottom: .7rem;
    margin-right: 1.5rem;
    font-size: .9rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
}

.navbar-text {
    padding-top: .625rem;
    padding-bottom: .625rem;
}

.navbar-toggler {
    padding: .5rem .5rem;
    font-size: 1rem;
    background: #fff;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .625rem;
    padding-left: .625rem;
}

.navbar-light {
    .navbar-brand {
        color: rgba(0, 0, 0, 0.9);

        &:focus,
        &:hover {
            color: rgba(0, 0, 0, 0.9);
        }
    }

    .navbar-nav {
        .nav-link {
            color: rgba(0, 0, 0, 0.5);

            &:focus,
            &:hover {
                color: rgba(0, 0, 0, 0.7);
            }

            &.disabled {
                color: rgba(0, 0, 0, 0.3);
            }
        }

        .active>.nav-link {
            color: rgba(0, 0, 0, 0.9);
        }

        .nav-link {

            &.active,
            &.show {
                color: rgba(0, 0, 0, 0.9);
            }
        }

        .show>.nav-link {
            color: rgba(0, 0, 0, 0.9);
        }
    }

    .navbar-toggler {
        color: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.1);
        background: 0 0;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .navbar-text {
        color: rgba(0, 0, 0, 0.5);

        a {
            color: rgba(0, 0, 0, 0.9);

            &:focus,
            &:hover {
                color: rgba(0, 0, 0, 0.9);
            }
        }
    }
}

.navbar-dark {
    .navbar-brand {
        color: #fff;

        &:focus,
        &:hover {
            color: #fff;
        }
    }

    .navbar-nav {
        .nav-link {
            color: rgba(255, 255, 255, 0.5);

            &:focus,
            &:hover {
                color: rgba(255, 255, 255, 0.75);
            }

            &.disabled {
                color: rgba(255, 255, 255, 0.25);
            }
        }

        .active>.nav-link {
            color: #fff;
        }

        .nav-link {

            &.active,
            &.show {
                color: #fff;
            }
        }

        .show>.nav-link {
            color: #fff;
        }
    }

    .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1);
        background: 0 0;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .navbar-text {
        color: rgba(255, 255, 255, 0.5);

        a {
            color: #fff;

            &:focus,
            &:hover {
                color: #fff;
            }
        }
    }
}

.card {
    background-color: #fff;
    border: none;
    border-radius: .625rem;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);

    >.list-group {
        &:first-child .list-group-item:first-child {
            border-top-left-radius: .625rem;
            border-top-right-radius: .625rem;
        }

        &:last-child .list-group-item:last-child {
            border-bottom-right-radius: .625rem;
            border-bottom-left-radius: .625rem;
        }
    }

    .list-group-item {
        padding: .8125rem 1.875rem;
    }

    .card-text {
        margin-bottom: 1.5625rem;
    }

    a:hover {
        text-decoration: none;
    }
}

.card-small {
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);

    .card-body,
    .card-footer,
    .card-header {
        padding: 1rem 1rem;
    }
}

.card-body {
    padding: 1.875rem;

    >p:last-child {
        margin-bottom: 0;
    }
}

.card-title {
    font-weight: 500;
    margin-bottom: .75rem;
}

.card-subtitle {
    margin-top: -1.09375rem;
}

.card-link {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

    +.card-link {
        margin-left: 1.875rem;
    }
}

.card-header {
    padding: 1.09375rem 1.875rem;
    background-color: #fff;
    border-bottom: none;

    &:first-child {
        border-radius: .625rem .625rem 0 0;
    }
}

.card-footer {
    padding: 1.09375rem 1.875rem;
    background-color: #fff;
    border-top: none;

    &:last-child {
        border-radius: 0 0 .625rem .625rem;
    }
}

.card-header-tabs {
    margin-bottom: -1rem;
    border-bottom: 0;

    .nav-link {
        border-bottom: transparent;

        &:hover {
            border-bottom: transparent;
        }
    }
}

.card-header-pills {
    margin-right: -.9375rem;
    margin-left: -.9375rem;

    &:hover {
        background: 0 0;
    }
}

.card-img-overlay {
    padding: 1.875rem 2.1875rem;
    background: rgba(90, 97, 105, 0.5);
    border-radius: .625rem;

    .card-title {
        color: #fff;
    }
}

.card-img {
    border-radius: .625rem;
}

.card-img-top {
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem;
}

.card-img-bottom {
    border-bottom-right-radius: .625rem;
    border-bottom-left-radius: .625rem;
}

.card-deck .card {
    margin-bottom: .9375rem;
}

.card-group {
    >.card {
        box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);

        &:last-child {

            .card-body,
            .card-footer {
                border-right: none;
            }
        }
    }

    .card-body,
    .card-footer {
        border-right: 1px solid #e7e9ea;
    }
}

.card-columns .card {
    margin-bottom: 2.1875rem;
}

.pagination {
    padding-left: 0;
    list-style: none;
    border-radius: .375rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: .875rem;
}

.page-link {
    padding: .5rem .75rem;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: none;
    margin: 0;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:focus,
    &:hover {
        color: #0056b3;
        background-color: #f5f5f6;
        border-color: #dfe1e3;
    }
}

.page-item {
    box-shadow: 0 0.125rem 0.9375rem rgba(90, 97, 105, 0.1), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.15);

    &:first-child {
        border-top-left-radius: .375rem;
        border-bottom-left-radius: .375rem;
        overflow: hidden;
    }

    &:last-child {
        border-top-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        overflow: hidden;

        .page-link {
            border-right: none;
        }
    }

    &.active .page-link {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

    &.disabled .page-link {
        color: #a8aeb4;
        background-color: #fff;
        border-color: #dfe1e3;
    }
}

.pagination-lg {
    .page-link {
        padding: .9375rem 1.5625rem;
        font-size: .875rem;
        line-height: 1.5;
    }

    .page-item {
        &:first-child .page-link {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
        }

        &:last-child .page-link {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
        }
    }
}

.pagination-sm {
    .page-link {
        padding: .25rem .6875rem;
        font-size: .6875rem;
        line-height: 1.5;
    }

    .page-item {
        &:first-child .page-link {
            border-top-left-radius: .35rem;
            border-bottom-left-radius: .35rem;
        }

        &:last-child .page-link {
            border-top-right-radius: .35rem;
            border-bottom-right-radius: .35rem;
        }
    }
}

.badge {
    padding: .375rem .5rem;
    font-size: 75%;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    border-radius: .375rem;
}

a.badge {
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.badge-pill {
    padding-right: .5rem;
    padding-left: .5rem;
    border-radius: 10rem;
}

.badge-squared {
    border-radius: 0;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #0062cc;
        }
    }
}

.badge-outline-primary {
    background: 0 0;
    border: 1px solid #007bff;
    color: #007bff;
}

.badge-secondary {
    color: #fff;
    background-color: #5a6169;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #42484e;
        }
    }
}

.badge-outline-secondary {
    background: 0 0;
    border: 1px solid #5a6169;
    color: #5a6169;
}

.badge-success {
    color: #fff;
    background-color: #17c671;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #129857;
        }
    }
}

.badge-outline-success {
    background: 0 0;
    border: 1px solid #17c671;
    color: #17c671;
}

.badge-info {
    color: #fff;
    background-color: #00b8d8;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #008da5;
        }
    }
}

.badge-outline-info {
    background: 0 0;
    border: 1px solid #00b8d8;
    color: #00b8d8;
}

.badge-warning {
    color: #212529;
    background-color: #ffb400;

    &[href] {

        &:focus,
        &:hover {
            color: #212529;
            text-decoration: none;
            background-color: #cc9000;
        }
    }
}

.badge-outline-warning {
    background: 0 0;
    border: 1px solid #ffb400;
    color: #ffb400;
}

.badge-danger {
    color: #fff;
    background-color: #c4183c;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #97122e;
        }
    }
}

.badge-outline-danger {
    background: 0 0;
    border: 1px solid #c4183c;
    color: #c4183c;
}

.badge-light {
    color: #212529;
    background-color: #fbfbfb;

    &[href] {

        &:focus,
        &:hover {
            color: #212529;
            text-decoration: none;
            background-color: #e2e2e2;
        }
    }
}

.badge-outline-light {
    background: 0 0;
    border: 1px solid #fbfbfb;
    color: #fbfbfb;
    color: #212529;
}

.badge-dark {
    color: #fff;
    background-color: #212529;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #0a0c0d;
        }
    }
}

.badge-outline-dark {
    background: 0 0;
    border: 1px solid #212529;
    color: #212529;
}

.jumbotron {
    padding: 38px 42px;
    margin-bottom: 2rem;
    background-color: #eceeef;
    border-radius: .5rem;
}

.alert {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0;
}

.alert-link {
    font-weight: 500;
}

.alert-dismissible .close {
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:hover {
        cursor: pointer;
    }
}

.alert-primary {
    color: #f5faff;
    background-color: #007bff;

    .alert-link {
        color: #f5faff;
    }
}

.alert-secondary {
    color: #d9dcdf;
    background-color: #5a6169;

    .alert-link {
        color: #d9dcdf;
    }
}

.alert-success {
    color: #d7fae9;
    background-color: #17c671;

    .alert-link {
        color: #d7fae9;
    }
}

.alert-info {
    color: #cef8ff;
    background-color: #00b8d8;

    .alert-link {
        color: #cef8ff;
    }
}

.alert-warning {
    color: #fffcf5;
    background-color: #ffb400;

    .alert-link {
        color: #fffcf5;
    }
}

.alert-danger {
    color: #fad7de;
    background-color: #c4183c;

    .alert-link {
        color: #fad7de;
    }
}

.alert-light {
    color: #fff;
    background-color: #fbfbfb;
    color: #212529;

    .alert-link {
        color: #fff;
        color: #212529;
    }
}

.alert-dark {
    color: #959faa;
    background-color: #212529;

    .alert-link {
        color: #959faa;
    }
}

.progress-wrapper {
    position: relative;
    color: #5a6169;

    .progress-label {
        font-size: .8125rem;
    }

    .progress-value {
        position: absolute;
        top: 6px;
        right: 0;
        color: #5a6169;
    }
}

.progress {
    height: .625rem;
    font-size: .625rem;
    line-height: .625rem;
    background-color: #f5f5f6;
    margin-top: 6px;
    border-radius: 1.25rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(90, 97, 105, 0.15);
}

.progress-sm {
    height: .3125rem;
}

.progress-lg {
    height: .9375rem;

    .progress-bar {
        height: .9375rem;
    }
}

.progress-bar {
    height: .625rem;
    line-height: .625rem;
    color: #fff;
    background-color: #007bff;
    transition: width .6s ease;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: .625rem .625rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

.list-group-small .list-group-item {
    padding: .625rem 1rem;
    font-size: .8125rem;
}

.list-group-item-action {
    color: #5a6169;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:focus,
    &:hover {
        color: #5a6169;
        background-color: #f7f8f8;
    }

    &:active {
        color: #5a6169;
        background-color: #eceeef;
    }
}

.list-group-item {
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: 300;

    &:first-child {
        border-top-left-radius: .375rem;
        border-top-right-radius: .375rem;
    }

    &:last-child {
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: .375rem;
    }

    &.disabled,
    &:disabled {
        color: #868e96;
        background-color: #fff;
    }

    &.active {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
    color: #004085;
}

a.list-group-item-primary {

    &:focus,
    &:hover {
        color: #004085;
        background-color: #9fcdff;
    }
}

button.list-group-item-primary {

    &:focus,
    &:hover {
        color: #004085;
        background-color: #9fcdff;
    }
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
    background-color: #004085;
    border-color: #004085;
}

.list-group-item-secondary {
    color: #2f3237;
    background-color: #d1d3d5;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
    color: #2f3237;
}

a.list-group-item-secondary {

    &:focus,
    &:hover {
        color: #2f3237;
        background-color: #c4c6c9;
    }
}

button.list-group-item-secondary {

    &:focus,
    &:hover {
        color: #2f3237;
        background-color: #c4c6c9;
    }
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
    background-color: #2f3237;
    border-color: #2f3237;
}

.list-group-item-success {
    color: #0c673b;
    background-color: #beefd7;
}

a.list-group-item-success,
button.list-group-item-success {
    color: #0c673b;
}

a.list-group-item-success {

    &:focus,
    &:hover {
        color: #0c673b;
        background-color: #aaeaca;
    }
}

button.list-group-item-success {

    &:focus,
    &:hover {
        color: #0c673b;
        background-color: #aaeaca;
    }
}

a.list-group-item-success.active,
button.list-group-item-success.active {
    background-color: #0c673b;
    border-color: #0c673b;
}

.list-group-item-info {
    color: #006070;
    background-color: #b8ebf4;
}

a.list-group-item-info,
button.list-group-item-info {
    color: #006070;
}

a.list-group-item-info {

    &:focus,
    &:hover {
        color: #006070;
        background-color: #a2e5f1;
    }
}

button.list-group-item-info {

    &:focus,
    &:hover {
        color: #006070;
        background-color: #a2e5f1;
    }
}

a.list-group-item-info.active,
button.list-group-item-info.active {
    background-color: #006070;
    border-color: #006070;
}

.list-group-item-warning {
    color: #855e00;
    background-color: #ffeab8;
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #855e00;
}

a.list-group-item-warning {

    &:focus,
    &:hover {
        color: #855e00;
        background-color: #ffe29f;
    }
}

button.list-group-item-warning {

    &:focus,
    &:hover {
        color: #855e00;
        background-color: #ffe29f;
    }
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
    background-color: #855e00;
    border-color: #855e00;
}

.list-group-item-danger {
    color: #660c1f;
    background-color: #eebec8;
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #660c1f;
}

a.list-group-item-danger {

    &:focus,
    &:hover {
        color: #660c1f;
        background-color: #e9aab7;
    }
}

button.list-group-item-danger {

    &:focus,
    &:hover {
        color: #660c1f;
        background-color: #e9aab7;
    }
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
    background-color: #660c1f;
    border-color: #660c1f;
}

.list-group-item-light {
    color: #838383;
    background-color: #fefefe;
}

a.list-group-item-light,
button.list-group-item-light {
    color: #838383;
}

a.list-group-item-light {

    &:focus,
    &:hover {
        color: #838383;
        background-color: #f1f1f1;
    }
}

button.list-group-item-light {

    &:focus,
    &:hover {
        color: #838383;
        background-color: #f1f1f1;
    }
}

a.list-group-item-light.active,
button.list-group-item-light.active {
    background-color: #838383;
    border-color: #838383;
}

.list-group-item-dark {
    color: #111315;
    background-color: #c1c2c3;
}

a.list-group-item-dark,
button.list-group-item-dark {
    color: #111315;
}

a.list-group-item-dark {

    &:focus,
    &:hover {
        color: #111315;
        background-color: #b4b5b6;
    }
}

button.list-group-item-dark {

    &:focus,
    &:hover {
        color: #111315;
        background-color: #b4b5b6;
    }
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
    background-color: #111315;
    border-color: #111315;
}

.close {
    font-size: 1.5rem;
    font-weight: 500;
    color: #8c949d;
    text-shadow: none;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:focus,
    &:hover {
        color: #8c949d;
    }
}

.modal {
    z-index: 1050;
}

.modal-dialog {
    margin: .625rem;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.modal-dialog-centered {
    min-height: calc(100% - (.625rem * 2));
}

.modal-content {
    background-color: #fff;
    border: none;
    border-radius: .5rem;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.modal-backdrop {
    z-index: 1040;
    background-color: #5a6169;

    &.show {
        opacity: .12;
    }
}

.modal-header {
    padding: .9375rem 2.1875rem;
    border-bottom: 1px solid #dfe1e3;
}

.modal-title {
    line-height: 1.5;
}

.modal-body {
    padding: 1.875rem 2.1875rem;
}

.modal-footer {
    padding: .9375rem 2.1875rem;
    border-top: 1px solid #dfe1e3;
}

.tooltip {
    z-index: 1070;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .6875rem;

    &.show {
        opacity: 1;
    }

    .arrow {
        width: 5px;
        height: 5px;
    }
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: 5px 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-width: 5px 2.5px 0;
    border-top-color: #fff;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 5px;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    width: 5px;
    height: 5px;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-width: 2.5px 5px 2.5px 0;
    border-right-color: #fff;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: 5px 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-width: 0 2.5px 5px;
    border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 5px;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    width: 5px;
    height: 5px;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-width: 2.5px 0 2.5px 5px;
    border-left-color: #fff;
}

.tooltip-inner {
    max-width: 200px;
    padding: 7px 13px;
    color: #5a6169;
    background-color: #fff;
    box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
    border-radius: .375rem;
}

.popover {
    z-index: 1060;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .6875rem;
    background-color: #fff;
    border: none;
    padding: 0;
    border-radius: .5rem;
    box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);

    .arrow {
        width: 10px;
        height: 5px;
        margin: 0 .5rem;

        &::before,
        &::after {
            border-width: 11px;
        }
    }
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: 5px;
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
    bottom: calc((5px + 1px) * -1);
}

.bs-popover-auto[x-placement^=top] .arrow {

    &::after,
    &::before {
        border-width: 5px 5px 0;
    }
}

.bs-popover-top .arrow {

    &::after,
    &::before {
        border-width: 5px 5px 0;
    }
}

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
    border-top-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: 5px;
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
    left: calc((5px + 1px) * -1);
    width: 5px;
    height: 10px;
    margin: .5rem 0;
}

.bs-popover-auto[x-placement^=right] .arrow {

    &::after,
    &::before {
        border-width: 5px 5px 5px 0;
    }
}

.bs-popover-right .arrow {

    &::after,
    &::before {
        border-width: 5px 5px 5px 0;
    }
}

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
    border-right-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
    left: 1px;
    border-right-color: #fff;
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: 5px;
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
    top: calc((5px + 1px) * -1);
}

.bs-popover-auto[x-placement^=bottom] .arrow {

    &::after,
    &::before {
        border-width: 0 5px 5px 5px;
    }
}

.bs-popover-bottom .arrow {

    &::after,
    &::before {
        border-width: 0 5px 5px 5px;
    }
}

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
    border-bottom-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    width: 10px;
    margin-left: -5px;
    border-bottom: 1px solid #f5f5f6;
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: 5px;
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
    right: calc((5px + 1px) * -1);
    width: 5px;
    height: 10px;
    margin: .5rem 0;
}

.bs-popover-auto[x-placement^=left] .arrow {

    &::after,
    &::before {
        border-width: 5px 0 5px 5px;
    }
}

.bs-popover-left .arrow {

    &::after,
    &::before {
        border-width: 5px 0 5px 5px;
    }
}

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
    border-left-color: rgba(0, 0, 0, 0.05);
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
    right: 1px;
    border-left-color: #fff;
}

.popover-header {
    padding: 14px 20px;
    font-size: 1rem;
    color: #3d5170;
    line-height: 14px;
    background-color: #f5f5f6;
    border-bottom: 1px solid #e7e9ea;
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px);
}

.popover-body {
    padding: 15px 20px;
    color: #5a6169;
}

.carousel {
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.carousel-item {
    transition: -webkit-transform .6s ease;
    transition: transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease;
}

.carousel-control-next,
.carousel-control-prev {
    width: 15%;
    color: #fff;
    opacity: .5;
}

.carousel-control-next {

    &:focus,
    &:hover {
        color: #fff;
    }
}

.carousel-control-prev {

    &:focus,
    &:hover {
        color: #fff;
    }
}

.carousel-control-next-icon {
    width: 20px;
    height: 20px;
}

.carousel-control-prev-icon {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    margin-right: 15%;
    margin-left: 15%;

    li {
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
    }

    .active {
        background-color: #fff;
    }
}

.carousel-caption {
    right: 15%;
    left: 15%;
    color: #fff;
}

.noUi-target {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    box-sizing: border-box;

    * {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: none;
        -ms-touch-action: none;
        touch-action: none;
        -ms-user-select: none;
        -moz-user-select: none;
        user-select: none;
        box-sizing: border-box;
    }

    position: relative;
    direction: ltr;
    background: #eceeef;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);
    margin: 35px 0;

    &:focus {
        outline: 0;
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.65), 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
    }
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    z-index: 0;
}

.noUi-connect,
.noUi-origin {
    position: absolute;
    will-change: transform;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.noUi-connect:focus,
.noUi-origin:focus {
    outline: 0;
}

.noUi-connect {
    background: #007bff;
    border-radius: 5px;
}

html:not([dir=rtl]) .noUi-horizontal {
    .noUi-origin {
        left: auto;
        right: 0;
    }

    .noUi-handle {
        right: -17px;
        left: auto;
    }
}

.noUi-rtl {
    .noUi-value-horizontal {
        -webkit-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
    }

    .noUi-value-vertical {
        -webkit-transform: translate(0, 50%);
        transform: translate(0, 50%);
    }
}

.noUi-vertical {
    width: 5px;

    .noUi-origin {
        width: 0;
    }

    .noUi-handle {
        left: -10px;
        top: -11.5px;

        &:after,
        &:before {
            width: 14px;
            height: 1px;
            left: 6px;
            top: 14px;
        }

        &:after {
            top: 17px;
        }
    }

    .noUi-tooltip {
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        top: 50%;
        right: 30px;
    }

    .noUi-draggable {
        cursor: ns-resize;
    }
}

.noUi-horizontal {
    height: 5px;

    .noUi-origin {
        height: 0;
    }

    .noUi-handle {
        left: -11.5px;
        top: -10px;
    }

    .noUi-tooltip {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        left: 50%;
        bottom: 30px;
    }
}

.noUi-handle {
    position: absolute;
    border: 1px solid #e7e9ea;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
    background: #fff;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &:hover {
        cursor: grab;
        cursor: -webkit-grab;
        cursor: -moz-grab;
    }

    &:active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
    }

    &:focus {
        outline: 0;
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.65), 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
    }

    &:after {
        left: 17px;
    }
}

.noUi-state-tap {

    .noUi-connect,
    .noUi-origin {
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
    }
}

.noUi-state-drag * {
    cursor: inherit !important;
}

.noUi-connects {
    border-radius: 5px;
}

.noUi-draggable {
    cursor: ew-resize;
}

.noUi-active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

[disabled] {
    .noUi-connect {
        background: #b8b8b8;
    }

    .noUi-handle,
    &.noUi-handle,
    &.noUi-target {
        cursor: not-allowed;
    }

    .noUi-handle {
        background: #f2f3f4;

        &:focus {
            box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
        }
    }
}

.noUi-pips {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    position: absolute;
    color: #a8aeb4;
    font-size: 12px;
}

.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #a8aeb4;
    font-size: 10px;
}

.noUi-marker {
    position: absolute;
    background: #a8aeb4;
}

.noUi-marker-sub,
.noUi-marker-large {
    background: #a8aeb4;
}

.noUi-pips-horizontal {
    padding: 10px 0;
    height: auto;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal {
    &.noUi-marker {
        margin-left: -1px;
        width: 1px;
        height: 4px;
    }

    &.noUi-marker-sub {
        height: 5px;
    }

    &.noUi-marker-large {
        height: 7px;
    }
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    padding-left: 15px;
}

.noUi-marker-vertical {
    &.noUi-marker {
        width: 4px;
        height: 1px;
        margin-top: -1px;
    }

    &.noUi-marker-sub {
        width: 10px;
    }

    &.noUi-marker-large {
        width: 7px;
    }
}

.noUi-tooltip {
    display: block;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    border-radius: .375rem;
    border-radius: .375rem;
    background: #fff;
    color: #5a6169;
    box-shadow: 0 3px 15px rgba(90, 97, 105, 0.1), 0 2px 3px rgba(90, 97, 105, 0.2);
    font-size: .75rem;
    padding: 5px 10px;
}

.slider-primary .noUi-connect {
    background: #007bff;
}

.slider-secondary .noUi-connect {
    background: #5a6169;
}

.slider-success .noUi-connect {
    background: #17c671;
}

.slider-info .noUi-connect {
    background: #00b8d8;
}

.slider-warning .noUi-connect {
    background: #ffb400;
}

.slider-danger .noUi-connect {
    background: #c4183c;
}

.slider-light .noUi-connect {
    background: #fbfbfb;
}

.slider-dark .noUi-connect {
    background: #212529;
}

.datepicker {
    border-radius: .625rem;
    direction: ltr;
}

.datepicker-inline {
    width: 220px;
}

.datepicker-rtl {
    direction: rtl;

    &.dropdown-menu {
        left: auto;
    }

    table tr td span {
        float: right;
    }
}

.datepicker-dropdown {
    top: 0;
    left: 0;
    padding: 20px 22px;

    &:after {
        content: '';
        display: inline-block;
        border-top: 0;
        position: absolute;
    }

    &:before {
        content: '';
        display: inline-block;
        border-top: 0;
        position: absolute;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #c3c7cc;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
    }

    &.datepicker-orient-left {
        &:before {
            left: 6px;
        }

        &:after {
            left: 7px;
        }
    }

    &.datepicker-orient-right {
        &:before {
            right: 6px;
        }

        &:after {
            right: 7px;
        }
    }

    &.datepicker-orient-bottom {
        &:before {
            top: -7px;
        }

        &:after {
            top: -6px;
        }
    }

    &.datepicker-orient-top {
        &:before {
            bottom: -7px;
            border-bottom: 0;
            border-top: 7px solid #c3c7cc;
        }

        &:after {
            bottom: -6px;
            border-bottom: 0;
            border-top: 6px solid #fff;
        }
    }
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    tr {
        td {
            border-radius: 50%;
        }

        th {
            border-radius: .375rem;
            font-weight: 500;
        }

        td,
        th {
            transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
            width: 36px;
            height: 36px;
            border: none;
            text-align: center;
        }
    }
}

.table-striped .datepicker table tr {

    td,
    th {
        background-color: transparent;
    }
}

.datepicker {
    table tr td {

        &.new,
        &.old {
            color: #c3c7cc;
        }

        &.day:hover,
        &.focused {
            background: #eceeef;
            cursor: pointer;
        }

        &.disabled {
            background: 0 0;
            color: #e7e9ea;
            cursor: default;

            &:hover {
                background: 0 0;
                color: #e7e9ea;
                cursor: default;
            }
        }

        &.highlighted {
            border-radius: 0;

            &.focused {
                background: #007bff;
            }

            &.disabled {
                background: #007bff;
                color: #5a6169;

                &:active {
                    background: #007bff;
                    color: #5a6169;
                }
            }
        }

        &.today {
            background: #e6f2ff;

            &.focused {
                background: #f5f5f6;
            }

            &.disabled {
                background: #f5f5f6;
                color: #868e96;

                &:active {
                    background: #f5f5f6;
                    color: #868e96;
                }
            }
        }

        &.range {
            background: #007bff;
            color: #fff;
            border-radius: 0;

            &.focused {
                background: #0067d6;
            }

            &.day.disabled:hover {
                background: #0062cc;
                color: #3395ff;
            }

            &.disabled {
                background: #0062cc;
                color: #3395ff;

                &:active {
                    background: #0062cc;
                    color: #3395ff;
                }
            }

            &.highlighted {
                &.focused {
                    background: #cbd3da;
                }

                &.disabled {
                    background: #e9ecef;
                    color: #e7e9ea;

                    &:active {
                        background: #e9ecef;
                        color: #e7e9ea;
                    }
                }
            }

            &.today.disabled {
                background: #007bff;
                color: #fff;

                &:active {
                    background: #007bff;
                    color: #fff;
                }
            }
        }

        &.day {
            &.range-start {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &.range-end {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.range-start.range-end {
                border-radius: 50%;
            }

            &.range:hover {
                background: #007bff;
                color: #fff;
            }
        }

        &.selected,
        &.active {
            background: #007bff;
            color: #fff;

            &.highlighted {
                background: #007bff;
                color: #fff;

                &:hover {
                    background: #007bff;
                    color: #fff;
                }
            }

            &:hover {
                background: #007bff;
                color: #fff;
            }
        }

        span {
            display: block;
            width: 23%;
            height: 54px;
            line-height: 54px;
            float: left;
            margin: 1%;
            cursor: pointer;
            border-radius: 4px;

            &.focused,
            &:hover {
                background: #e9ecef;
            }

            &.disabled {
                background: 0 0;
                color: #e7e9ea;
                cursor: default;

                &:hover {
                    background: 0 0;
                    color: #e7e9ea;
                    cursor: default;
                }
            }

            &.active {
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

                &.disabled {
                    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

                    &:hover {
                        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                    }
                }

                &:hover {
                    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                }
            }

            &.new,
            &.old {
                color: #868e96;
            }
        }
    }

    .datepicker-switch {
        width: 145px;
        cursor: pointer;
    }

    .next,
    .prev,
    tfoot tr th {
        cursor: pointer;
    }

    .datepicker-switch:hover,
    .next:hover,
    .prev:hover,
    tfoot tr th:hover {
        background: #e9ecef;
    }

    .next.disabled,
    .prev.disabled {
        visibility: hidden;
    }

    .cw {
        font-size: 10px;
        width: 12px;
        padding: 0 2px 0 5px;
        vertical-align: middle;
    }
}

.input-daterange input {
    text-align: center;
}

.bg-primary {
    background-color: #007bff !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #0062cc !important;
        }

        .card-footer,
        .card-header {
            background: #0074f0;
        }
    }
}

a.bg-primary {

    &:focus,
    &:hover {
        background-color: #0062cc !important;
    }
}

.bg-secondary {
    background-color: #5a6169 !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #42484e !important;
        }

        .card-footer,
        .card-header {
            background: #535961;
        }
    }
}

a.bg-secondary {

    &:focus,
    &:hover {
        background-color: #42484e !important;
    }
}

.bg-success {
    background-color: #17c671 !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #129857 !important;
        }

        .card-footer,
        .card-header {
            background: #15b869;
        }
    }
}

a.bg-success {

    &:focus,
    &:hover {
        background-color: #129857 !important;
    }
}

.bg-info {
    background-color: #00b8d8 !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #008da5 !important;
        }

        .card-footer,
        .card-header {
            background: #00abc9;
        }
    }
}

a.bg-info {

    &:focus,
    &:hover {
        background-color: #008da5 !important;
    }
}

.bg-warning {
    background-color: #ffb400 !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #cc9000 !important;
        }

        .card-footer,
        .card-header {
            background: #f0a900;
        }
    }
}

a.bg-warning {

    &:focus,
    &:hover {
        background-color: #cc9000 !important;
    }
}

.bg-danger {
    background-color: #c4183c !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #97122e !important;
        }

        .card-footer,
        .card-header {
            background: #b61638;
        }
    }
}

a.bg-danger {

    &:focus,
    &:hover {
        background-color: #97122e !important;
    }
}

.bg-light {
    background-color: #fbfbfb !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #e2e2e2 !important;
        }

        .card-footer,
        .card-header {
            background: #f3f3f3;
        }
    }
}

a.bg-light {

    &:focus,
    &:hover {
        background-color: #e2e2e2 !important;
    }
}

.bg-dark {
    background-color: #212529 !important;

    &.card {

        .card-body,
        .card-footer,
        .card-header,
        .card-title {
            background-color: #0a0c0d !important;
        }

        .card-footer,
        .card-header {
            background: #1a1d21;
        }
    }
}

a.bg-dark {

    &:focus,
    &:hover {
        background-color: #0a0c0d !important;
    }
}

.border {
    border: 1px solid #e1e5eb !important;
}

.border-top {
    border-top: 1px solid #e1e5eb !important;
}

.border-right {
    border-right: 1px solid #e1e5eb !important;
}

.border-bottom {
    border-bottom: 1px solid #e1e5eb !important;
}

.border-left {
    border-left: 1px solid #e1e5eb !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #5a6169 !important;
}

.border-success {
    border-color: #17c671 !important;
}

.border-info {
    border-color: #00b8d8 !important;
}

.border-warning {
    border-color: #ffb400 !important;
}

.border-danger {
    border-color: #c4183c !important;
}

.border-light {
    border-color: #fbfbfb !important;
}

.border-dark {
    border-color: #212529 !important;
}

.rounded {
    border-radius: 0.375rem !important;
}

.rounded-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
}

.rounded-right {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.rounded-left {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.text-monospace {
    font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.font-weight-normal {
    font-weight: 300;
}

.font-weight-bold {
    font-weight: 500;
}

.text-primary {
    color: #007bff !important;
}

a.text-primary {

    &:focus,
    &:hover {
        color: #0062cc !important;
    }
}

.text-secondary {
    color: #5a6169 !important;
}

a.text-secondary {

    &:focus,
    &:hover {
        color: #42484e !important;
    }
}

.text-success {
    color: #17c671 !important;
}

a.text-success {

    &:focus,
    &:hover {
        color: #129857 !important;
    }
}

.text-info {
    color: #00b8d8 !important;
}

a.text-info {

    &:focus,
    &:hover {
        color: #008da5 !important;
    }
}

.text-warning {
    color: #ffb400 !important;
}

a.text-warning {

    &:focus,
    &:hover {
        color: #cc9000 !important;
    }
}

.text-danger {
    color: #c4183c !important;
}

a.text-danger {

    &:focus,
    &:hover {
        color: #97122e !important;
    }
}

.text-light {
    color: #fbfbfb !important;
}

a.text-light {

    &:focus,
    &:hover {
        color: #e2e2e2 !important;
    }
}

.text-dark {
    color: #212529 !important;
}

a.text-dark {

    &:focus,
    &:hover {
        color: #0a0c0d !important;
    }
}

.text-body {
    color: #5a6169 !important;
}

a.text-white {

    &:focus,
    &:hover {
        color: #e6e6e6 !important;
    }
}

.text-black {
    color: #000;
}

a.text-black {

    &:focus,
    &:hover {
        color: #000 !important;
    }
}

.text-muted {
    color: #868e96 !important;
}

.with-shadows {
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

html {
    font-size: 16px;
    font-weight: 500;
}

body {
    background: #f5f6f8;
    font-size: 15px;
    font-weight: 500;
}

a {
    color: #007bff;

    &:hover {
        color: #0056b3;
    }
}

label {
    font-weight: 400;
}

.datepicker {
    font-size: .75rem;
    padding: .625rem;

    table tr {

        td,
        th {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
}

.btn-black:not([disabled]):not(.disabled) {

    &.active,
    &:active {
        box-shadow: none !important;
        background-color: #007bff;
        border-color: #007bff;
        color: #fff;
    }
}

.btn-white:not([disabled]):not(.disabled) {

    &.active,
    &:active {
        box-shadow: none !important;
        background-color: #007bff;
        border-color: #007bff;
        color: #fff;
    }
}

.btn-black:not([disabled]):not(.disabled) {

    &.active+.btn,
    &:active+.btn {
        border-left: 1px solid #007bff;
    }
}

.btn-white:not([disabled]):not(.disabled) {

    &.active+.btn,
    &:active+.btn {
        border-left: 1px solid #007bff;
    }
}

.btn-black:hover,
.btn-white:hover {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 0.125rem 0.625rem rgba(129, 142, 163, 0.2), 0 0.0625rem 0.125rem rgba(129, 142, 163, 0.3);
}

.btn.btn-white {
    border: 1px solid #e1e5eb;
    color: #3d5170;

    &:not([disabled]):not(.disabled) {
        &.active-primary {

            &.active,
            &:active {
                background-color: #007bff;
                border-color: #007bff;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-secondary {

            &.active,
            &:active {
                background-color: #5a6169;
                border-color: #5a6169;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-success {

            &.active,
            &:active {
                background-color: #17c671;
                border-color: #17c671;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-info {

            &.active,
            &:active {
                background-color: #00b8d8;
                border-color: #00b8d8;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-warning {

            &.active,
            &:active {
                background-color: #ffb400;
                border-color: #ffb400;
                color: #212529;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-danger {

            &.active,
            &:active {
                background-color: #c4183c;
                border-color: #c4183c;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-light {

            &.active,
            &:active {
                background-color: #fbfbfb;
                border-color: #fbfbfb;
                color: #212529;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }

        &.active-dark {

            &.active,
            &:active {
                background-color: #212529;
                border-color: #212529;
                color: #fff;
            }

            &.active+.btn,
            &:active+.btn {
                border-left: 1px solid red;
            }
        }
    }
}

.btn-accent {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    box-shadow: none;

    &:hover {
        color: #fff;
        background-color: #006fe6;
        border-color: #006fe6;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.disabled,
    &:disabled {
        background-color: #007bff;
        border-color: #007bff;
        box-shadow: none;
        cursor: not-allowed;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #006fe6;
            border-color: #0062cc;
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
        }
    }
}

.show>.btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #006fe6;
    border-color: #0062cc;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-outline-accent {
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
    color: #007bff;

    &:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25);
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15), 0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    }

    &.disabled,
    &:disabled {
        color: #007bff;
        background-color: transparent;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
    }
}

.show>.btn-outline-accent.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-accent:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
}

.show>.btn-outline-accent.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.btn-group-sm i {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.navbar-light .navbar-brand {
    font-weight: 500;
    color: #3d5170;
}

.nav-link {
    font-size: .8125rem;
    font-weight: 400;
}

.nav-link-icon {
    color: #818ea3;

    &:focus,
    &:hover {
        color: #66748b;
    }
}

.nav-link-icon__wrapper {
    position: relative;
}

.nav-link-icon i {
    font-size: 1.25rem;

    &.material-icons {
        font-size: 1.5625rem;
    }
}

.badge-accent {
    color: #fff;
    background-color: #007bff;

    &[href] {

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #0062cc;
        }
    }
}

.badge-outline-accent {
    background: 0 0;
    border: 1px solid #007bff;
    color: #007bff;
}

.alert-accent {
    color: #f5faff;
    background-color: #007bff;

    .alert-link {
        color: #f5faff;
    }
}

.alert-dismissible .close {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.input-group {
    min-width: 7.5rem;
}

.input-group-text {
    font-size: .8125rem;
}

.input-group.input-group-seamless> {

    .custom-select:not(:first-child),
    .form-control:not(:first-child) {
        padding-left: 1.875rem;
    }
}

.input-group-text i {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

    &.fa {
        font-size: .75rem;
    }

    &.material-icons {
        top: 0;
        font-size: .8125rem;
    }
}

.custom-select-sm {
    font-size: .6875rem;
    border-radius: .25rem;
}

.custom-select-lg {
    border-radius: .25rem;
}

.custom-control {
    line-height: 1.5rem;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::after {
    border-color: #becad6;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::after,
.custom-toggle .custom-control-input:checked:disabled~.custom-control-label::after {
    background: #becad6;
}

.slider-accent .noUi-connect {
    background: #007bff;
}

.noUi-pips {
    font-size: .625rem;
}

.noUi-tooltip {
    font-size: .75rem;
    padding: 5px 10px;
}

.noUi-horizontal {
    .noUi-tooltip {
        bottom: 1.625rem;
    }

    .noUi-handle {
        left: -.625rem;
        top: -.5rem;
    }
}

.noUi-handle {
    width: 23px;
    height: 23px;
}

.thumbnail {
    border: 1px solid #d4d4d4;
}

i.material-icons {
    top: 2px;
}

.dropdown {

    .fa,
    .material-icons {
        color: #c3c7cc;
        margin-right: .25rem;
    }

    .dropdown-item:hover {
        background-color: #fafafb;
    }
}

.card-header {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
    }
}

.card .view-report {
    margin-top: auto;
    margin-bottom: auto;
    font-size: .75rem;

    a {
        color: #3d5170;

        &:hover {
            color: #007bff;
        }
    }
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-auto {
    overflow: auto;
}

.no-shadow {
    box-shadow: none !important;
}

.text-fiord-blue {
    color: #3d5170 !important;
}

a.text-fiord-blue {

    &:focus,
    &:hover {
        color: #2b394f !important;
    }
}

.text-shuttle-gray {
    color: #5a6169 !important;
}

a.text-shuttle-gray {

    &:focus,
    &:hover {
        color: #42484e !important;
    }
}

.text-reagent-gray {
    color: #818ea3 !important;
}

a.text-reagent-gray {

    &:focus,
    &:hover {
        color: #66748b !important;
    }
}

.text-mischka {
    color: #cacedb !important;
}

a.text-mischka {

    &:focus,
    &:hover {
        color: #acb2c6 !important;
    }
}

.text-athens-gray {
    color: #e9ecef !important;
}

a.text-athens-gray {

    &:focus,
    &:hover {
        color: #cbd3da !important;
    }
}

.text-accent {
    color: #007bff !important;
}

a.text-accent {

    &:focus,
    &:hover {
        color: #0062cc !important;
    }
}

.text-light {
    color: #818ea3 !important;
}

.text-semibold {
    font-weight: 400;
}

.border {
    border: 1px solid #e1e5eb !important;
}

.border-top {
    border-top: 1px solid #e1e5eb !important;
}

.border-right {
    border-right: 1px solid #e1e5eb !important;
}

.border-bottom {
    border-bottom: 1px solid #e1e5eb !important;
}

.border-left {
    border-left: 1px solid #e1e5eb !important;
}

.rounded {
    border-radius: 5px !important;
}

.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.rounded-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rounded-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.border-accent {
    border-color: #007bff !important;
}

.main-navbar .navbar {
    height: 3.75rem;

    >* {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    .nav-link {
        min-width: 3.75rem;
    }

    .notifications {
        position: relative;

        .badge {
            position: absolute;
            padding: .25rem .375rem;
            font-size: .5rem;
            left: 50%;
            top: 50%;
        }

        .dropdown-menu {
            padding: 0;
            min-width: 25rem;
            border-right: 0;
            left: auto;

            .dropdown-item {
                white-space: normal;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-flow: row;
                flex-flow: row;
                padding-top: .625rem;
                padding-bottom: .625rem;
                border-bottom: 1px solid #e1e5eb;

                &:last-child {
                    border-bottom: 0;
                }
            }

            .notification__icon-wrapper {
                display: -ms-flexbox;
                display: flex;
                padding: 0 5px;
            }

            .notification__icon {
                background-color: #f5f6f8;
                box-shadow: 0 0 0 1px #fff, inset 0 0 3px rgba(0, 0, 0, 0.2);
                width: 2.1875rem;
                height: 2.1875rem;
                line-height: 0;
                display: block;
                text-align: center;
                margin: auto;
                border-radius: 50%;

                i {
                    color: #818ea3;
                    line-height: 2.0625rem;
                    font-size: 1.0625rem;
                    margin: 0;
                }
            }

            .notification__content {
                padding: 0 .625rem;

                p {
                    margin: 0;
                    line-height: 1.5;
                    font-size: .75rem;
                }
            }

            .notification__category {
                font-size: .5625rem;
                color: #818ea3;
                letter-spacing: .0938rem;
                display: inline-block;
                text-transform: uppercase;
                margin-bottom: 5px;
                font-weight: 500;
            }

            .notification__all {
                display: block;
                font-weight: 500;
                font-size: 11px;
                border-bottom-left-radius: .375rem;
                border-bottom-right-radius: .375rem;
            }
        }
    }

    .user-avatar {
        max-width: 2.5rem;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        right: 0;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
    }

    .nav-link-icon i {
        line-height: 2.5rem;
    }
}

.main-content {

    .header-navbar,
    >.main-navbar {
        box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
    }
}

.main-sidebar {
    top: 0;
    position: fixed;
    height: calc(100vh);
    background: #fff;
    z-index: 1070;
    will-change: transform;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);

    &.open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
    }

    .toggle-sidebar {
        position: absolute;
        right: 0;
        height: 100%;
        padding: 1.25rem;
        font-size: 1.25rem;
        border-left: 1px solid #e1e5eb;

        &:hover {
            cursor: pointer;
        }
    }

    .navbar-brand {
        overflow: hidden;
        height: 3.75rem;
        font-size: 1rem;
    }

    .nav-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 3.75rem - 1px);
    }

    .nav {

        .nav-item,
        .nav-link {
            white-space: nowrap;
            min-width: 100%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
            will-change: background-color, box-shadow, color;
            transition: box-shadow .2s ease, color .2s ease, background-color .2s ease;
            font-size: .85rem;
        }

        .nav-item {
            .nav-link {
                border-bottom: 1px solid #e1e5eb;
                font-weight: 400;
                color: #3d5170;
                padding: .9375rem 1.5625rem;

                i {
                    min-width: 1.25rem;
                    font-size: 90%;
                    text-align: center;
                    vertical-align: middle;
                    will-change: color;
                    color: #cacedb;
                    transition: color .2s ease;
                    margin-right: .375rem;

                    &.material-icons {
                        font-size: 1.125rem;
                        top: -1px;
                    }
                }

                &.active,
                &:hover {
                    box-shadow: inset .1875rem 0 0 #007bff;
                    background-color: #fbfbfb;
                    color: #007bff;
                }
            }

            &.active,
            &:hover {
                box-shadow: inset .1875rem 0 0 #007bff;
                background-color: #fbfbfb;
                color: #007bff;
            }

            .nav-link {

                &.active i,
                &:hover i {
                    color: #007bff;
                }
            }

            &.active i,
            &:hover i {
                color: #007bff;
            }
        }
    }

    .nav--no-borders {
        .nav-item .nav-link {
            border-bottom: 0;
        }

        .dropdown-menu {
            box-shadow: inset 0 0 0.4375rem rgba(61, 81, 112, 0.2);

            .dropdown-item:first-child {
                border-top: 1px solid #e1e5eb;
            }
        }
    }

    .dropdown-menu {
        position: static !important;
        -webkit-transform: translate(0) !important;
        transform: translate(0) !important;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        border: none;
        padding: 0;
        box-shadow: inset 0 -0.1875rem 0.1875rem rgba(61, 81, 112, 0.08);

        .dropdown-item {
            padding: .75rem 1.75rem;
            border-bottom: 1px solid #f0f2f5;
            color: #3d5170;
            font-size: .8125rem;
            font-weight: 400;

            &.active {
                color: #007bff;
            }

            &:hover {
                color: #007bff;
                background: 0 0;
            }

            &.active {
                background-color: #fbfbfb;
            }

            &:last-of-type {
                border-bottom: 1px solid #e1e5eb;
            }
        }

        .dropdown-divider {
            margin: 0;
        }
    }

    .dropdown-toggle {
        position: relative;

        &::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
            background-position: center center;
            width: .875rem;
            height: .5625rem;
            transition: -webkit-transform 250ms ease-in-out;
            transition: transform 250ms ease-in-out;
            transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
            border: none;
            position: absolute;
            top: 50%;
            right: .625rem;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: .1;
            will-change: transform;
        }
    }

    .dropdown.show .dropdown-toggle::after {
        -webkit-transform: translateY(-50%) rotateZ(180deg);
        transform: translateY(-50%) rotateZ(180deg);
    }
}

.main-navbar__search .input-group-prepend .input-group-text,
.main-sidebar__search .input-group-prepend .input-group-text {
    font-size: .6875rem;
    padding: .75rem 1.0625rem;
}

.main-navbar__search input.form-control,
.main-sidebar__search input.form-control {
    border: none;
    font-size: .8125rem;
    border-radius: 0;
}

.main-navbar__search input.form-control {

    &:focus,
    &:hover {
        box-shadow: none;
    }
}

.main-sidebar__search input.form-control {

    &:focus,
    &:hover {
        box-shadow: none;
    }
}

.main-content>.main-content-container.container-fluid {
    min-height: calc(100vh - 7.5rem);
}

.main-footer {
    height: 3.75rem;

    .copyright {
        color: #818ea3;
    }
}

.page-header {
    .page-title {
        font-size: 1.625rem;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        font-size: 20px;
        font-weight: 900;
        padding: 0;
    }

    .page-subtitle {
        letter-spacing: .125rem;
        color: #818ea3;
        font-size: .625rem;
    }
}

.stats-small {
    min-height: 8.7rem;

    canvas {
        position: absolute;
        bottom: 0;
    }
}

.stats-small__data {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-flow: column;
    flex-flow: column;
    max-width: 50%;
    z-index: 1;
}

.stats-small__label {
    font-size: .625rem;
    letter-spacing: .0625rem;
    color: #818ea3;
}

.stats-small__value {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}

.stats-small__percentage {
    position: relative;
    display: table;
    margin-left: auto;
    padding-left: .9375rem;
}

.stats-small__percentage--decrease,
.stats-small__percentage--increase {
    font-size: .75rem;
}

.stats-small__percentage--decrease::before {
    content: "";
    width: .75rem;
    height: .375rem;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-position: center center;
    background-repeat: no-repeat;
}

.stats-small__percentage--increase {
    &::before {
        content: "";
        width: .75rem;
        height: .375rem;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTdjNjcxIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz4gPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiA8L3N2Zz4=);
    }

    color: #17c671;
}

.stats-small__percentage--decrease {
    color: #c4183c;

    &::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYzQxODNjIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
    }
}

.stats-small--1 {
    .stats-small__data {
        max-width: 100%;
    }

    .stats-small__percentage {
        margin: 0 auto;
    }

    .stats-small__value {
        font-size: 2.0625rem;
    }

    .stats-small__label,
    .stats-small__percentage {
        font-size: .75rem;
    }

    canvas {
        opacity: .5;
    }
}

.card-group .stats-small {
    position: relative;
    overflow: hidden;
}

.quick-post-form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    -ms-flex: 1;
    flex: 1;

    .form-group:nth-child(2) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1;
        flex: 1;
    }

    textarea {
        resize: none;
        min-height: 100px;
    }
}

.blog-comments__avatar img {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: .25rem;
}

.blog-comments__item {
    padding: 0;
    border-bottom: 1px solid #e1e5eb;

    &:last-child {
        border: 0;
    }
}

.blog-comments__actions {
    font-size: 95%;

    .btn-group button {
        padding: .25rem .5625rem;
    }
}

.add-new-post__editor {
    min-height: 400px;
}

html {

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
        border-color: #e1e5eb;
    }

    .ql-container {
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }

    .ql-editor {

        b,
        strong {
            font-weight: 600;
        }
    }

    .ql-toolbar {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        color: #3d5170 !important;

        .ql-fill {
            fill: #818ea3;
        }

        .ql-stroke {
            &.ql-fill {
                fill: #818ea3;
            }

            stroke: #818ea3;
        }

        button {
            &:active {

                .ql-fill,
                .ql-stroke.ql-fill {
                    fill: #007bff !important;
                }
            }

            &:focus {

                .ql-fill,
                .ql-stroke.ql-fill {
                    fill: #007bff !important;
                }
            }

            &:hover {

                .ql-fill,
                .ql-stroke.ql-fill {
                    fill: #007bff !important;
                }
            }

            &:active .ql-stroke,
            &:focus .ql-stroke,
            &:hover .ql-stroke {
                stroke: #007bff !important;
            }
        }

        .ql-picker {
            .ql-picker-item:hover {
                color: #007bff !important;
            }

            .ql-picker-options {
                padding: 6px 20px;
                border: 0 !important;
                box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
                border-radius: .375rem;
            }
        }
    }
}

.card-post {
    .card-body {
        padding: 1.5625rem 1.5625rem;
    }

    .card-footer,
    .card-header {
        padding-left: 1.5625rem;
        padding-right: 1.5625rem;
    }
}

.card-post__image {
    position: relative;
    min-height: 10.3125rem;
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card-post__author-avatar {
    width: 2.8125rem;
    height: 2.8125rem;
    box-shadow: 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
    display: block;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    text-indent: -9999px;
}

.card-post__author-avatar--small {
    width: 2.1875rem;
    height: 2.1875rem;
}

.card-post__author-name {
    font-weight: 500;
}

.card-post--aside {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
    flex-flow: row;

    .card-post__image {
        border-top-right-radius: 0;
        border-top-left-radius: .625rem;
        border-bottom-left-radius: .625rem;
        min-width: 180px;
    }
}

.card-post--1 {

    .card-post__author,
    .card-post__category {
        position: absolute;
    }

    .card-post__author {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        margin-left: 1.5625rem;
        position: absolute;
        bottom: 0;
    }

    .card-post__category {
        top: .9375rem;
        right: .9375rem;
        position: absolute;
        text-transform: uppercase;
    }

    .card-body {
        padding-top: 2.1875rem;
    }

    &.card-post--aside {
        .card-body {
            padding: 1.5625rem 1.5625rem;
        }

        .card-post__author {
            left: .9375rem;
            bottom: .9375rem;
            -webkit-transform: none;
            transform: none;
            margin: 0;
        }

        .card-post__category {
            right: initial;
            top: .9375rem;
            left: .9375rem;
        }
    }
}

.error {
    height: calc(100vh - 3.75rem);
    display: -ms-flexbox;
    display: flex;
}

.error__content {
    padding: 0 .9375rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    margin: auto;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;

    h2 {
        color: #cacedb;
        font-weight: 700;
        font-size: 3.75rem;
        margin-bottom: 1.5625rem;
    }

    h3 {
        font-weight: 500;
        font-size: 2.1875rem;
        margin-bottom: .625rem;
    }

    p {
        color: #818ea3;
    }
}

@media (max-width: 575.98px) {
    html {
        font-size: 15px;
    }
}

@media (min-width: 576px) {
    .form-inline .form-check-input {
        margin-right: .313rem;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .625rem;
        padding-left: .625rem;
    }

    .card-deck {
        margin-right: -.9375rem;
        margin-left: -.9375rem;

        .card {
            margin-right: .9375rem;
            margin-left: .9375rem;
        }
    }

    .card-group {
        box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
        border-radius: .625rem;

        >.card {
            box-shadow: none;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:only-child {
                border-radius: .625rem;

                .card-header,
                .card-img-top {
                    border-top-left-radius: .625rem;
                    border-top-right-radius: .625rem;
                }

                .card-footer,
                .card-img-bottom {
                    border-bottom-right-radius: .625rem;
                    border-bottom-left-radius: .625rem;
                }
            }

            &:not(:first-child):not(:last-child):not(:only-child) {
                border-radius: 0;

                .card-footer,
                .card-header,
                .card-img-bottom,
                .card-img-top {
                    border-radius: 0;
                }
            }
        }
    }

    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;
    }

    .jumbotron {
        padding: 4rem 2rem;
    }

    .modal-dialog {
        max-width: 500px;
        margin: 1.875rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.875rem * 2));
    }

    .modal-content {
        box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .625rem;
        padding-left: .625rem;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .625rem;
        padding-left: .625rem;
    }

    .modal-lg {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .625rem;
        padding-left: .625rem;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.5),
(min-resolution: 144dpi) {
    html {
        font-size: 16px;
        font-weight: 400;
    }

    .main-sidebar .dropdown-menu .dropdown-item {
        font-weight: 300;
    }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    body {
        font-size: 13px;
        font-weight: 400;
    }
}

@media (max-width: 767.98px) {
    .main-navbar .navbar .notifications {
        position: static;

        .dropdown-menu {
            min-width: 100% !important;
            border-left: none;
            left: 0;
            right: 0;
        }
    }

    .main-sidebar {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        box-shadow: none;
    }

    .main-sidebar__search {
        box-sizing: border-box;
        border-right: 0 !important;
        padding: .625rem 0;
        border-bottom: 1px solid #e1e5eb;

        .input-group {
            margin: 0 !important;
        }
    }

    .main-footer {
        display: block !important;
        height: auto;

        .nav {
            width: 100%;
            display: block;
            border-bottom: 1px solid #e1e5eb;
            padding-bottom: .75rem;
        }

        .copyright {
            display: inline-block;
            width: 100%;
            padding: 1rem;
            text-align: center;
        }
    }

    .page-header {
        .page-title {
            font-size: 2rem;
        }

        .page-subtitle {
            font-size: .8125rem;
            font-weight: 400;
        }
    }
}

@media (max-width: 991.98px) {
    .main-sidebar .navbar-brand {
        font-size: 90%;
    }
}