:root {
    --vote-button-box-shadow: 0 5px 10px;
}

#listen-page {
    width: 100%;
}

.contribution.listen {
    & .instruction svg {
        max-height: 20px;

        & path {
            fill: var(--valid-green);
        }
    }

    & .pill {
        @media (--sm-down) {
            &.valid .num {
                color: var(--valid-green);
            }

            &.invalid .num {
                color: var(--red);
            }
        }

        & svg {
            margin: 0 23px;

            & path {
                fill: var(--warm-grey);
            }
        }

        &.valid path {
            fill: var(--valid-green);
        }

        &.invalid path {
            fill: var(--red);
        }
    }

    & .vote-button {
        width: 100px;
        height: 46px;

        @media (--md-up) {
            width: 130px;
            height: 57px;
        }

        border: none;
        border-radius: 30px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        background: var(--white);

        &,
        & path {
            transition: all var(--transition-duration) linear;
        }

        &.yes {
            box-shadow: var(--vote-button-box-shadow)
                color-mod(var(--valid-green) alpha(30%));

            &:hover,
            &:active {
                background: var(--valid-green);
                box-shadow: var(--vote-button-box-shadow)
                    color-mod(var(--valid-green) alpha(70%));
            }
        }

        &.no {
            box-shadow: var(--vote-button-box-shadow)
                color-mod(var(--red) alpha(20%));

            &:hover,
            &:active {
                background: var(--red);
                box-shadow: var(--vote-button-box-shadow)
                    color-mod(var(--red) alpha(60%));
            }
        }

        path {
            fill: var(--near-black);
        }

        &:hover,
        &:active {
            color: var(--white);

            & path {
                fill: var(--white);
            }
        }

        &.yes:disabled,
        &.no:disabled {
            color: var(--near-black);
            background: var(--white);
            box-shadow: var(--vote-button-box-shadow)
                color-mod(var(--grey) alpha(80%));

            &:hover {
                path {
                    fill: var(--near-black);
                }
            }
        }

        & svg {
            margin-inline-end: 10px;

            @media (--md-up) {
                margin-inline-end: 23px;
            }
        }
    }

    .error-card {
        .record-instead {
            display: inline-flex;

            & svg {
                margin-inline-end: 10px;
            }

            & path {
                fill: var(--white);
            }
        }
    }

    .no-clips-for-variant {
        .button {
            background: var(--blue);
            box-shadow: 0 5px 10px color-mod(var(--blue) alpha(50%));

            &:hover {
                background: var(--blue);
                box-shadow: 0 5px 10px var(--blue);
            }
        }
    }
}

.listen-abort {
    &.inner {
        @media (--md-up) {
            min-height: 55vh;
        }

        @media (--sm-down) {
            height: 100vh;
        }
    }

    & .title {
        margin-top: 58px;
        margin-bottom: 10px;
        font-size: var(--font-size-xxl);
        font-weight: normal;
    }

    & .text {
        margin-bottom: 35px;
        font-size: 20px;
    }

    & .buttons {
        margin-bottom: 40px;
    }

    & .button {
        height: 57px;
    }

    & .text-button {
        font-size: var(--font-size-xs);
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            color: var(--blue);
            text-decoration-color: var(--blue);
        }
    }
}
