.container {
	display: flex;
	margin-top: 8px;

	.emojiPicker {
		position: absolute;
		top: 0px;
		margin-left: 20px;
	}

	&.hide {
		display: none;
	}
}
