#node-NONE {
	display: none;
}

.union-message-block {
	display: flex;
	flex-direction: column;
	border: 1px solid hsla(0, 0%, 100%, 0.3);
	background: #edeef9 !important;
	padding-bottom: 1px;
	min-width: 206px;
	border-radius: 5px;

	.add-new-message-button {
		padding: 4px;
		text-align: center;
		background: white;
		margin: 4px;
		font-size: 14px;
		border: 1px solid whitesmoke;
		border-radius: 5px;
	}

	.header {
		background: #fff;
		padding: 4px 13px 3px;
		border-radius: 5px 5px 0 0;
		margin-bottom: 11px !important;
		opacity: 1 !important;
		display: flex;

		.detail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			flex: 1;
		}

		h6 {
			margin-left: 12px;
			font-weight: 600;
			margin-top: 8px;
			margin-bottom: 5px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			font-size: 13px;
			vertical-align: baseline;
			-webkit-font-smoothing: antialiased;
			text-rendering: optimizeLegibility;
			letter-spacing: -0.4px;
			margin-bottom: 1px;
			margin-right: 7px;
			line-height: 1.2;
			margin-left: 13px;
			margin-bottom: 8px;
			font-weight: 400;
			cursor: text;

			&:focus {
				border: none;
				outline: none;
			}
		}

		.icon {
			font-size: 22px;
			display: flex;
			justify-content: center;
			align-items: center;

			.img {
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.option-container {
			margin: auto;
		}

		.option {
			background-color: Transparent;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			overflow: hidden;
			outline: none;
		}
	}

	.content {
		.message {
			border: 2px solid #fff;
			letter-spacing: -0.4px;
			line-height: 1.3;
			border: 1px solid #fff;
			background-color: #fff;
			box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.11);
			position: relative;
			padding: 16px;
			font-weight: 400;
			border-radius: 5px;
		}

		.media {
			position: relative;

			>img {
				width: 100%;
				height: 175px;
				object-fit: cover;
			}
		}

		.button {
			position: relative;

			.item {
				margin-bottom: 2px;
				background-color: #cd3c79;
				border-radius: 2px;
				color: #fff;
				letter-spacing: -0.3px;
				border: 2px solid transparent;
				cursor: pointer;
				padding: 12px;
				padding-left: 16px;
				font-weight: 400;
				position: relative;

				&.default {
					background-color: #7075a7;
				}
			}
		}

		textarea {
			border: none;
			background: none;
			padding: 0;
			color: #45496e;
			width: 100%;

			&:focus {
				outline: none;
			}

			&::placeholder {
				color: #999;
			}
		}

		input {
			border: none;
			background: none;
			padding: 0;
			color: white;
			width: 100%;

			&:focus {
				outline: none;
			}

			&::placeholder {
				color: #ddd;
			}
		}

		.add-container {
			button {
				color: #fff;
				min-height: auto;
				box-shadow: none;
				padding: 6px 8px;
				font-size: 10px;
				font-weight: 500;
				border: none !important;
				cursor: default;
				cursor: pointer;
				background-color: #c2c4d3;
				box-shadow: none;
				color: #707a9f;
				width: fit-content;

				&.selected {
					box-shadow: 0 0 11px -4px #000;
					background: #fff;
					color: #373d5a;
				}

				&:first-child {
					border-top-left-radius: 14px;
					border-bottom-left-radius: 14px;
				}

				&:last-child {
					border-top-right-radius: 14px;
					border-bottom-right-radius: 14px;
				}
			}

			margin-left: 9px;
			margin-bottom: 12px;
		}

		.sub-message-container {
			margin: 0 12px 9px 9px;

			.message-menu:hover,
			.message:hover .message-menu,
			.button .item:hover .message-menu,
			.media:hover .message-menu {
				display: inline;
			}

			.message-menu {
				position: absolute;
				left: inherit;
				right: 95%;
				top: -15px;
				display: none;
				z-index: 2;

				.message-menu-body:hover {
					box-shadow: 0 0 50px #548bfd, 0 0 15px #548cfd81, 0 2px 4px #0000003d;
				}

				.message-menu-body {
					min-height: auto;
					padding: 6px 8px;
					font-size: 10px;
					font-weight: 500;
					border: none !important;
					cursor: default;
					box-shadow: none;
					border-radius: 14px;
					background: #fff;
					color: #373d5a;
					width: fit-content;
					display: flex;
					box-shadow: 0 0 15px #00000081, 0 0 15px #00000034,
						0 2px 4px #0000003d;
					transition: box-shadow 0.3s, display 0.3s;

					.message-id {
						margin: 5px;
					}
				}
			}
		}
	}

	.header {

		.message-menu:hover,
		&:hover .message-menu {
			display: inline;
		}

		.message-menu {
			position: absolute;
			left: inherit;
			right: calc(100% - 50px);
			top: -25px;
			display: none;
			z-index: 2;

			.message-menu-body:hover {
				box-shadow: 0 0 50px #548bfd, 0 0 15px #548cfd81, 0 2px 4px #0000003d;
			}

			.message-menu-body {
				min-height: auto;
				padding: 6px 8px;
				font-size: 10px;
				font-weight: 500;
				border: none !important;
				cursor: default;
				box-shadow: none;
				border-radius: 14px;
				background: #fff;
				color: #373d5a;
				width: fit-content;
				display: flex;
				box-shadow: 0 0 15px #00000081, 0 0 15px #00000034, 0 2px 4px #0000003d;
				transition: box-shadow 0.3s, display 0.3s;

				.message-id {
					margin: 5px;
				}
			}
		}
	}

	.content {

		.media,
		.message {
			.detail-maps {
				position: absolute;
				bottom: 5px;
				left: 5px;
				border-radius: 3px;
				display: none;
				color: #fff;
				padding: 5px;
				background-color: rgba(0, 0, 0, 0.5);

				p {
					font-size: 12px;
					margin: 0;
					padding: 0;
				}
			}

			&:hover .detail-maps {
				display: inline;
			}

			.edit-container {
				position: absolute;
				top: 0px;
				left: 0px;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 3px;
				display: none;
			}

			&:hover .edit-container {
				display: inline;
			}

			.edit-container {
				button {
					flex-direction: row;
					border: none;
					border-radius: 3px;
					cursor: pointer;
					vertical-align: middle;
					min-width: 24px;
					min-height: 24px;
					padding: 0 4px;
					background: none;
					z-index: 4;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&:hover {
					background: rgba(100, 100, 100, 0.5);
				}

				button svg {
					fill: white;
				}
			}
		}
	}
}

.union-message-block-end {
	display: flex;
	flex-direction: column;
	border: 1px solid hsla(0, 0%, 100%, 0.3);
	background: #edeef9 !important;
	padding-bottom: 1px;
	min-width: 206px;
	border-radius: 5px;

	.header {
		background: #fff;
		padding: 4px 13px 3px;
		border-radius: 5px 5px 0 0;
		opacity: 1 !important;
		display: flex;

		.detail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			flex: 1;
		}

		h6 {
			margin-left: 12px;
			font-weight: 600;
			margin-top: 8px;
			margin-bottom: 5px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			font-size: 13px;
			vertical-align: baseline;
			-webkit-font-smoothing: antialiased;
			text-rendering: optimizeLegibility;
			letter-spacing: -0.4px;
			margin-bottom: 1px;
			margin-right: 7px;
			line-height: 1.2;
			margin-left: 13px;
			margin-bottom: 8px;
			font-weight: 400;
			cursor: text;

			&:focus {
				border: none;
				outline: none;
			}
		}

		.icon {
			font-size: 22px;
			display: flex;
			justify-content: center;
			align-items: center;

			.img {
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.option-container {
			margin: auto;
		}

		.option {
			background-color: Transparent;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			overflow: hidden;
			outline: none;
		}
	}

	.header {

		.message-menu:hover,
		&:hover .message-menu {
			display: inline;
		}

		.message-menu {
			position: absolute;
			left: inherit;
			right: calc(100% - 50px);
			top: -25px;
			display: none;
			z-index: 2;

			.message-menu-body:hover {
				box-shadow: 0 0 50px #548bfd, 0 0 15px #548cfd81, 0 2px 4px #0000003d;
			}

			.message-menu-body {
				min-height: auto;
				padding: 6px 8px;
				font-size: 10px;
				font-weight: 500;
				border: none !important;
				cursor: default;
				box-shadow: none;
				border-radius: 14px;
				background: #fff;
				color: #373d5a;
				width: fit-content;
				display: flex;
				box-shadow: 0 0 15px #00000081, 0 0 15px #00000034, 0 2px 4px #0000003d;
				transition: box-shadow 0.3s, display 0.3s;

				.message-id {
					margin: 5px;
				}
			}
		}
	}
}

.union-message-block-header {
	display: flex;
	flex-direction: column;
	border: 1px solid hsla(0, 0%, 100%, 0.3);
	background: #edeef9 !important;
	padding-bottom: 1px;
	min-width: 206px;
	border-radius: 5px;

	.add-new-message-button {
		padding: 4px;
		text-align: center;
		background: white;
		margin: 4px;
		font-size: 14px;
		border: 1px solid whitesmoke;
		border-radius: 5px;
	}

	.header {
		background: #fff;
		padding: 4px 13px 3px;
		border-radius: 5px 5px 0 0;
		opacity: 1 !important;
		display: flex;

		.detail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			flex: 1;
		}

		h6 {
			margin-left: 12px;
			font-weight: 600;
			margin-top: 8px;
			margin-bottom: 5px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			font-size: 13px;
			vertical-align: baseline;
			-webkit-font-smoothing: antialiased;
			text-rendering: optimizeLegibility;
			letter-spacing: -0.4px;
			margin-bottom: 1px;
			margin-right: 7px;
			line-height: 1.2;
			margin-left: 13px;
			margin-bottom: 8px;
			font-weight: 400;
			cursor: text;

			&:focus {
				border: none;
				outline: none;
			}
		}

		.icon {
			font-size: 22px;
			display: flex;
			justify-content: center;
			align-items: center;

			.img {
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.option-container {
			margin: auto;
		}

		.option {
			background-color: Transparent;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			overflow: hidden;
			outline: none;
		}
	}
}

.question-message-block {
	display: flex;
	flex-direction: column;
	border: 1px solid hsla(0, 0%, 100%, 0.3);
	background: #fff !important;
	padding-bottom: 1px;
	min-width: 400px;
	border-radius: 5px;

	.header {
		background: #fff;
		padding: 4px 13px 3px;
		border-radius: 5px 5px 0 0;
		opacity: 1 !important;
		display: flex;
		position: relative;

		&:hover {
			.settings {
				display: block;
				cursor: pointer;
			}
		}

		.settings {
			// position: absolute;
			display: none;
			// right: 18px;
			align-self: center;
			padding-inline: 10px;

			svg {
				fill: #45496e;
			}
		}

		.detail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			flex: 1;
		}

		h6 {
			margin-left: 12px;
			font-weight: 600;
			margin-top: 8px;
			margin-bottom: 5px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			font-size: 13px;
			vertical-align: baseline;
			-webkit-font-smoothing: antialiased;
			text-rendering: optimizeLegibility;
			letter-spacing: -0.4px;
			margin-bottom: 1px;
			margin-right: 7px;
			line-height: 1.2;
			margin-left: 13px;
			margin-bottom: 8px;
			font-weight: 400;
			cursor: text;

			&:focus {
				border: none;
				outline: none;
			}
		}

		.icon {
			font-size: 22px;
			display: flex;
			justify-content: center;
			align-items: center;

			.img {
				width: 25px;
				height: 25px;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.option-container {
			margin: auto;
		}

		.option {
			background-color: Transparent;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			overflow: hidden;
			outline: none;
		}
	}

	.message-menu:hover,
	&:hover .message-menu {
		display: inline;
	}

	.message-menu {
		position: absolute;
		left: inherit;
		right: calc(100% - 50px);
		top: -25px;
		display: none;
		z-index: 2;

		.message-menu-body:hover {
			box-shadow: 0 0 50px #548bfd, 0 0 15px #548cfd81, 0 2px 4px #0000003d;
		}

		.message-menu-body {
			min-height: auto;
			padding: 6px 8px;
			font-size: 10px;
			font-weight: 500;
			border: none !important;
			cursor: default;
			box-shadow: none;
			border-radius: 14px;
			background: #fff;
			color: #373d5a;
			width: fit-content;
			display: flex;
			box-shadow: 0 0 15px #00000081, 0 0 15px #00000034, 0 2px 4px #0000003d;
			transition: box-shadow 0.3s, display 0.3s;

			.message-id {
				margin: 5px;
			}
		}
	}
}

.message-selection-container {
	border-radius: 20px;
	height: 340px;
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;
	position: relative;
	z-index: 99999000;
	background-color: rgb(56, 65, 95);

	.body {
		color: white;

		.message-selection-search {
			padding: 10px;
			display: flex;
			position: relative;
			flex-direction: column;
			background-color: #1d2333;
			z-index: 10;

			.message-selection-search-header {
				padding: 5px;
			}

			.message-selection-search-input {
				border-radius: 5px;
				height: 45px;
			}
		}

		.message-selection-features {
			margin-top: 97px;
			padding-bottom: 8px;
			background-color: rgba(0, 0, 0, 0.45);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			/* Increase/Decrease this value for cross-browser compatibility */
			overflow-y: scroll;
			overflow-x: hidden;
		}

		.header {
			padding: 10px 16px;
			position: sticky;
			top: 0px;
			background-color: #171b27;
			z-index: 9;

			p {
				color: hsla(0, 0%, 100%, 0.56);
				font-size: 13px;
				letter-spacing: -0.1px;
				font-weight: 300;
				margin: 0;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				word-wrap: normal;
				padding: 0;
				line-height: 17px;
				position: sticky;
				text-transform: uppercase;
			}
		}

		.message-option-container {
			display: flex;
			padding: 7px 16px;
			cursor: pointer;
			width: 100%;

			&:hover {
				background-color: rgba(255, 255, 255, 0.05);
			}

			.image-container {
				width: 38px;
				height: 38px;
				border-radius: 3px;
				margin-right: 10px;
				border: 1px solid hsla(0, 0%, 100%, 0.2);
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.message-option-item {
				text-align: left;

				.title {
					text-transform: uppercase;
					font-weight: 500;
					line-height: 1.5em;
					font-size: 12px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					word-wrap: normal;
					margin: 0;
				}

				.description {
					letter-spacing: -0.3px;
					font-weight: 300;
					line-height: 1.5em;
					font-size: 11px;
					opacity: 0.7;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					word-wrap: normal;
					margin: 0;
				}
			}
		}
	}
}

.entry-port-container {
	// margin: 2px;
	// position: absolute;
	// top: 0px;
	// bottom: 0px;
	// left: 0px;
	// right: 0px;
	// transition: box-shadow 0.3s;

	// &:hover {
	// 	box-shadow: 0 0 15px 0 #32d295, 0 0 15px 0 rgba(50, 210, 149, 0.59),
	// 		0 2px 4px 0 rgba(0, 0, 0, 0.24) !important;
	// }
}

.piece-end-point {
	// position: absolute;
	// right: -12px;
	// top: calc(50% - 10px);
	// width: 20px;
	// height: 20px;
	// border: 2px solid #fff;
	// cursor: pointer;
	// transition: transform 0.15s;
	// border-radius: 50%;
	// background-color: #3ccfa0;
	// -webkit-transition: width 0.2s, height 0.2s, margin 0.2s;
	// /* For Safari 3.1 to 6.0 */
	// transition: width 0.2s, height 0.2s, margin 0.2s;

	&:hover {
		// width: 24px;
		// height: 24px;
		// margin-top: -2px;
		// margin-right: -2px;

		.piece-end-point__drag-helper-img {
			opacity: 1;
		}
	}

	// /* Add crosshair/plus sign */
	// &::before,
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	background-color: #fff;
	// 	pointer-events: none;
	// 	/* Make the crosshair non-interactive */
	// }

	// &::before {
	// 	width: 2px;
	// 	height: 10px;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// }

	// &::after {
	// 	width: 10px;
	// 	height: 2px;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// }
}

.piece-end-point-intro {
	position: absolute;
	right: -12px;
	top: calc(50% - 10px);
	width: 20px;
	height: 20px;
	border: 2px solid #fff;
	cursor: pointer;
	transition: transform 0.15s;
	border-radius: 50%;
	background-color: #3ccfa0;
	-webkit-transition: width 0.2s, height 0.2s, margin 0.2s;
	/* For Safari 3.1 to 6.0 */
	transition: width 0.2s, height 0.2s, margin 0.2s;
}

.piece-end-point_action_uri {
	position: absolute;
}

.piece-end-point__drag-helper {
	position: absolute;
	left: 100%;
	top: 10px;
	-webkit-transition: opacity 0.25s ease-in-out;
	transition: opacity 0.25s ease-in-out;
	pointer-events: none;
	cursor: auto;
	height: 27px;
	min-width: 198px;

	img {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		vertical-align: baseline;
		text-rendering: optimizeLegibility;
		height: auto;
		opacity: 0;
		max-width: 100%;
		transform: translateX(-5px);
		transition: opacity 0.35s, transform 0.15s;
	}
}

button.transparent {
	background-color: Transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	width: 35px;
}

.start-here-image {
	content: "";
	background-image: url(https://static.helloumi.com/daisho/img/start-here.png);
	width: 100px;
	height: 100px;
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	right: 100%;
	top: -75px;
}

.end-here-image {
	content: "";
	background-image: url(https://cdn.doszy.com/orders/user-123/order-1/0cd37833cb08bc8090a9cffbfbecb3c32d13ea20bb3c3e0ca9f8238b6dc6b309.png);
	width: 100px;
	height: 100px;
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	left: 100%;
	top: -75px;
}

.img.img-loaded {
	max-width: 20px;
	max-height: 20px;
	border-radius: 5px;
}

.button-trigger-container {
	position: relative;

	input {
		background-color: transparent;
		outline: none;
		border: 0;
		color: white;
	}

	.item {
		margin-bottom: 2px;
		background-color: #cd3c79;
		border-radius: 2px;
		color: #fff;
		letter-spacing: -0.3px;
		border: 2px solid transparent;
		cursor: pointer;
		padding: 12px;
		padding-left: 16px;
		font-weight: 400;
		position: relative;

		>div {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		&.default {
			background-color: #7075a7;
		}

		&.next-message {
			background-color: #4a508b;
		}
	}
}

.plan-tag {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	font-weight: 900;
	margin-top: -12px;
	margin-right: -40px;
	width: 45px;
	top: 0;
	padding: 0 30px;
	height: 20px;
	background-color: rgb(221, 221, 255);
}

.header-and-messageId {
	display: flex;
	justify-content: space-between;
}