@charset "utf-8";

.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

strong {
	font-size: 16px;
	font-weight: 700;
}

p {
	font-size: 16px;
}

.bp3-divider {
	margin: 16px 0;
	border-right: 1px solid rgba(16, 22, 26, 0.15);
	border-bottom: 1px solid rgba(16, 22, 26, 0.15);
}

.panel {
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-default {
	border-color: #ddd;
}

.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.panel-default > .panel-heading {
	color: #333;
	background-color: #f5f5f5;
	border-color: #ddd;
}

.panel-body {
	padding: 15px;
}

.label {
	display: inline;
	padding: 0.2em 0.6em 0.3em;
	font-size: 75%;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
	user-select: none;
	cursor: pointer;
}

.label-info {
	background-color: #5bc0de;
}

.label-success {
	background-color: #5cb85c;
}

.comment-form-head.tabnav {
	background: #f6f8fa;
	border-radius: 3px 3px 0 0;
	padding: 6px 10px 0;
	border: 1px solid #dfe2e5;
}

.previewable-comment-form .tabnav {
	padding: 8px 8px 0;
	position: relative;
}

.tabnav {
	border-bottom: 1px solid #d1d5da;
	margin-bottom: 5px;

	/* margin-top: 15px; */
}

.tabnav-tabs {
	margin-bottom: -1px;
}

.tabnav-tab.selected {
	background-color: #fff;
	border-color: #d1d5da;
	border-radius: 3px 3px 0 0;
	color: #24292e;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
	-webkit-appearance: button;
}

.tabnav-tab {
	background-color: initial;
	border: 1px solid transparent;
	border-bottom: 0;
	color: #586069;
	display: inline-block;
	font-size: 10px;
	line-height: 20px;
	padding: 8px 12px;
	text-decoration: none;
}

.choice-search-input {
	background: linear-gradient(to bottom, #fff, #f7f9fa) !important;
	color: #5f6c72 !important;
	padding: 9px 8px 7px 2.8em !important;
	height: 40px !important;
	min-height: 40px !important;
	border: 0 !important;
	-moz-border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	border-radius: 0 !important;
}

.svg-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	fill: currentColor;
}

.choices-search__search-icon {
	fill: #969ea2;
	position: absolute;
	top: 7px;
	left: 12px;
}

.choice-step-number {
	margin-right: 2%;
	background-color: #f1f4f5;
	color: #5f6c72;
	display: inline-block;
	text-align: center;
	height: 22px;
	width: 22px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.7;
	margin-right: 0rem;
}

.dropdown-item {
	user-select: none;
	cursor: pointer;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}

.dropdown-menu.show {
	max-height: 230px;
	overflow-y: auto;
	overflow-x: hidden;
	max-width: 300px;

	&::-webkit-scrollbar {
		width: 0px;

		/* remove scrollbar space */
		background: transparent;

		/* optional: just make scrollbar invisible */
	}
}

.message::-webkit-scrollbar {
	width: 0px;

	/* remove scrollbar space */
	background: transparent;

	/* optional: just make scrollbar invisible */
}

.btn-link {
	cursor: pointer;
	user-select: none;
}

.ace_editor {
	width: 100% !important;
}

.ace_text-input {
	position: absolute !important;
}

.header-p {
	margin-bottom: 8px;
}

.tabs-left > .nav-tabs {
	border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
	display: none;
}

.tab-content > .active,
.pill-content > .active {
	display: block;
}

.tabs-left > .nav-tabs {
	> li {
		float: none;

		> a {
			min-width: 74px;
			margin-right: 0;
			margin-bottom: 3px;
			margin-right: -1px;
			-webkit-border-radius: 4px 0 0 4px;
			-moz-border-radius: 4px 0 0 4px;
			border-radius: 4px 0 0 4px;

			&:hover,
			&:focus {
				border-color: #eeeeee #dddddd #eeeeee #eeeeee;
			}
		}
	}

	float: left;
	margin-right: 19px;
	border-right: 1px solid #ddd;

	.active > a {
		border-color: #ddd transparent #ddd #ddd;
		*border-right-color: #ffffff;

		&:hover,
		&:focus {
			border-color: #ddd transparent #ddd #ddd;
			*border-right-color: #ffffff;
		}
	}
}

.nav-link.active:hover {
	color: white !important;
}

.auth-login-icon {
	margin-left: 8px;
	width: 32px;
	height: 26px;
	margin-top: 4px;
	font-size: 18px;
}

.switch-container {
	display: flex;
	color: #343756;
	font-weight: 500;
	font-size: 13px;
}

.switch-container-label {
	flex: 1;
	margin: auto;
}

.switch-container-option {
	flex: 1;
}

.form-option-switch {
	display: flex;
	padding: 14px;

	button {
		cursor: pointer;
		font-weight: 600;
		letter-spacing: -0.3px;
		padding: 9px 16px;
		border-radius: 3px !important;
		box-shadow: none;
		position: relative;
		flex: 1;
		text-transform: none;
		font-size: 12px;
		background-color: #e2e5ef;
		border: none;
		color: #7f84a7;

		&.active {
			background-color: #fff !important;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
			color: #343756;
		}
	}
}

.image {
	display: block;
	position: relative;

	&.is-64x64 {
		height: 64px;
		width: 64px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

button:focus {
	outline: 0;
}

iframe {
	border: 0;
}

a:hover {
	text-decoration: none;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 767px) {
	.col-xs-0 {
		display: none !important;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
	.col-sm-0 {
		display: none !important;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
	.col-md-0 {
		display: none !important;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.col-lg-0 {
		display: none !important;
	}
}

.loading-page {
	display: flex;
	height: 100vh;
}

.loading-container {
	margin: auto;
	width: 100%;
	text-align: center;
	padding: 16px;
	display: flex;
	justify-content: center;
	height: 100%;
	flex-direction: column;

	.bubbles-loading {
		margin: 0 auto;
	}

	h4 {
		font-size: 20px;
		font-weight: 900;
		margin-top: 14px;
	}

	h6 {
		margin-top: 14px;
	}
}

.bp3-button {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	padding: 5px 10px;
	vertical-align: middle;
	text-align: left;
	font-size: 14px;
	min-width: 30px;
	min-height: 30px;
	background-image: none !important;
	font-weight: 700;

	box-shadow: none !important;

	i {
		margin-right: 4px;
	}

	&.bp3-intent-danger {
		box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
		background-color: #cd3c79;
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
		color: #fff;

		&:disabled {
			background: rgb(232, 89, 149);
		}
	}

	&.bp3-intent-primary {
		-webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4),
			inset 0 -1px 0 rgba(16, 22, 26, 0.2);
		box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
		background-color: #137cbd;
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(hsla(0, 0%, 100%, 0.1)),
			to(hsla(0, 0%, 100%, 0))
		);
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
		color: #fff;
	}

	&.bp3-intent-success {
		-webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4),
			inset 0 -1px 0 rgba(16, 22, 26, 0.2);
		box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
		background-color: #0f9960;
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(hsla(0, 0%, 100%, 0.1)),
			to(hsla(0, 0%, 100%, 0))
		);
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
		color: #fff;
	}

	&.bp3-intent-white {
		background-color: white;
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
		color: #45496e;
		border: 1px solid #e1e5eb;
	}
}

.input-container {
	margin-top: 10px;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0px;
	}

	.protocal {
		width: 70px;
		text-align: center;
	}

	.domain {
		width: 140px;
		text-align: center;
	}
}

.FormInputImage__preview__Image {
	max-width: 200px;
	max-height: 86px;
	margin-right: 13px;
}

.FormInputImage__preview {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

button {
	&.transparent {
		background: none;
		color: inherit;
		border: none;
	}
}

.invalid-feedback {
	display: block !important;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
}

.invalid-feedback-tranparent {
	display: block !important;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
	background-color: #ffffff;
}

.txt-center {
	text-align: center;
}

.product-container {
	.rt-td {
		
	}
}

.node {
	cursor: grab !important;
}

.nav-link {
	cursor: pointer;
}

.fa {
	&.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}