body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #45496e;
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5;
}

/* Font for English */
[data-i18n-lang="en"] body {
	font-family: "Poppins", sans-serif !important;
}

/* Font for Thai */
[data-i18n-lang="th"] body {
	font-family: "Bai Jamjuree", sans-serif !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.sub-header-button {}

.save-menu {
	padding-left: 20px;
}

.input-group>.rbt .rbt-input-hint-container {
	display: unset !important;
}

.css-e110bw {
	z-index: 99999;
}