.box {
	position: relative;
	padding-left: 34px;

	.timeline {
		.line {
			border-right-style: dotted;
			border-color: #bbbcc5;
			position: absolute;
			top: 34px;
			bottom: 10px;
			left: 20px;
		}

		.step {
			position: absolute;
			left: 0px;
			top: 3px;
			width: 28px;
			height: 28px;
			background-color: #bbbcc5;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 8px;
			font-weight: 700;
			color: white;
		}
	}

	.mapTitle {
		margin: 0.5em 0;
		p {
			margin: 0;
		}
		input {
			color: #000000;
			width: 100%;
			letter-spacing: -0.4px;
			line-height: 1.3;
			border: 1px solid #fff;
			background-color: #fff;
			box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.11);
			position: relative;
			padding: 16px;
			font-weight: 400;
			border-radius: 5px;

			&:focus {
				outline: none;
			}
		}
	}
}

.buttonContainer {
	display: flex;

	.imageBox {
		width: 29px;
		height: 24px;
		border-radius: 5px;
		margin-right: 12px;
		background-size: cover;
	}
}
