.react-flow__nodes .react-flow__node-forceLayout {
	z-index: 1001 !important;
	min-width: auto !important;
	padding: 30px !important;
	background-color: #ffffff !important;
	border-radius: 50% !important;
	border-width: 4px !important;
	border-style: solid !important;
	border-color: #00cbff !important;
	width: 15vh;
	height: 14vh;
}

.react-flow__nodes .react-flow__node-forceLayout .react-flow__handle {
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
	opacity: 0 !important;
	pointer-events: none !important;
}